import {useEffect, useState} from "react";
import IUser from "../../Interfaces/IUser";
import axios from "axios";
import {Modal} from "antd";
import IAntdPagination from "../../Interfaces/IAntdPagination";

const useUsers = (page: number, reload: boolean) => {
	const [users, setUsers] = useState<IUser[]>([])
	const [pagination, setPagination] = useState<IAntdPagination>()

	useEffect(() => {
		axios.get('/users?page=' + page)
			.then((response) => {
				setUsers(response.data.data)
				const pagination: IAntdPagination = {
					total: response.data.meta.total,
					pageSize: response.data.meta.per_page,
					current: response.data.meta.current_page,
				}
				setPagination(pagination)
			})
			.catch((error) => {
				console.log(error)
				Modal.error({
					content: 'Greška: '+error,
				});
			})
	}, [page, reload])

	return {
		users,
		pagination
	}
}

export default useUsers