import React, {useEffect} from 'react';
import './App.css';
import {BrowserRouter, Route} from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import DashboardSelect from "./Pages/DashboardSelect";
import Cases from "./Pages/Cases/Cases";
import Investors from "./Pages/Investors/Investors";
import Users from "./Pages/Users/Users";
import UserCreate from"./Pages/Users/UserCreate"
import CaseDetails from "./Components/CaseDetails"
import UserEdit from "./Pages/Users/UserEdit";
import Login from "./Pages/Login";
import InvestorCreate from "./Pages/Investors/InvestorCreate";
import InvestorEdit from "./Pages/Investors/InvestorEdit";
import CaseEdit from "./Pages/Cases/CaseEdit";
import BodoviPage from "./Pages/BodoviPage";
import Forbidden from "./Pages/Forbidden";
import NotificationsPage from "./Pages/NotificationsPage";
import useIsAdmin from "./Hooks/Users/useIsAdmin";
import useIsAuthed from "./Hooks/Users/useIsAuthed";
import ProfilePage from "./Pages/Profile/ProfilePage";
import Izvestaji from "./Pages/Izvestaji/Izvestaji";

function App() {
  const {authed} = useIsAuthed()
  const {isAdmin} = useIsAdmin()

  useEffect(() => {
    initDefaultFilters()
  }, []);

  const initDefaultFilters = () => {
    let onlyMineCasesFilter = localStorage.getItem('onlyMineCases')
    if (onlyMineCasesFilter === null) localStorage.setItem('onlyMineCases', 'false')
    let inProgress = localStorage.getItem('inProgress')
    if (inProgress === null) localStorage.setItem('inProgress', 'true')
    let waitingForState = localStorage.getItem('waitingForState')
    if (waitingForState === null) localStorage.setItem('waitingForState', 'true')
    let waitingForInvestor = localStorage.getItem('waitingForInvestor')
    if (waitingForInvestor === null) localStorage.setItem('waitingForInvestor', 'true')
    let waitingForGeometar = localStorage.getItem('waitingForGeometar')
    if (waitingForGeometar === null) localStorage.setItem('waitingForGeometar', 'true')
    let waitingForThirdPerson = localStorage.getItem('waitingForThirdPerson')
    if (waitingForThirdPerson === null) localStorage.setItem('waitingForThirdPerson', 'true')
    let waitingForPayment = localStorage.getItem('waitingForPayment')
    if (waitingForPayment === null) localStorage.setItem('waitingForPayment', 'true')
    let caseStopped = localStorage.getItem('caseStopped')
    if (caseStopped === null) localStorage.setItem('caseStopped', 'true')
    let arhivirani = localStorage.getItem('arhivirani')
    if (arhivirani === null) localStorage.setItem('arhivirani', 'true')
    let ownerSwitch = localStorage.getItem('specificOwner')
    if (ownerSwitch === null) localStorage.setItem('specificOwner', 'false')
    // Posle svakog logouta, ova se komponenta ponovo renderuje i setuje na default vrednosti
    // Malo se komplikuje situacija kada korisnik nije admin, moze dobiti adminova podesavanja ukoliko je admin
    // pre njega bio logovan. Tako da je manja steta da se i adminu resetuje posle logouta na default podesavanje.
    let onlyVerified = localStorage.getItem('onlyVerified')
    if (onlyVerified === null || !isAdmin) localStorage.setItem('onlyVerified', 'true')
    let onlyUnVerified = localStorage.getItem('onlyUnVerified')
    if (onlyUnVerified === null || !isAdmin) localStorage.setItem('onlyUnVerified', 'true')
  }

  return (
      <BrowserRouter>
        <Route path={'/login'} exact          component={Login}/>
        <Route path={'/'} exact               component={authed ? Dashboard : Login}/>
        <Route path={'/table'}                component={authed ? DashboardSelect : Login}/>
        <Route path={'/predmeti'} exact       component={authed ? Cases : Login}/>
        <Route path={'/predmeti/edit/:id'}    component={authed ? CaseEdit : Login}/>
        <Route path={'/predmet/:id'}          component={authed ? CaseDetails : Login}/>
        <Route path={'/investitori'} exact    component={authed ? Investors : Login}/>
        <Route path={'/investitori/novi'}     component={authed ? InvestorCreate : Login}/>
        <Route path={'/investitori/edit/:id'} component={authed ? InvestorEdit : Login}/>
        <Route path={'/korisnici'} exact      component={authed ? Users : Login}/>
        <Route path={'/korisnici/edit/:id'}   component={authed ? UserEdit : Login}/>
        <Route path={'/korisnici/novi'}       component={authed ? UserCreate : Login}/>
        <Route path={'/bodovi'}               component={isAdmin ? BodoviPage : Login}/>
        <Route path={'/notifikacije'}         component={authed ? NotificationsPage : Login}/>
        <Route path={'/profilna'}             component={authed ? ProfilePage : Login}/>
        <Route path={'/izvestaji'}            component={isAdmin ? Izvestaji : Login}/>
        <Route path={'/forbidden'}            component={Forbidden}/>
      </BrowserRouter>
  )
}

export default App;

