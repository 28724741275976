import {useEffect, useState} from "react";
import axios from "axios";
import {Modal} from "antd";

type IReportRow = {
	points: number
	name: string,
	user_id: number
}

const useGetBodoviZaPeriod = (fromDate: string, toDate: string) => {
	const [report, setReport] = useState<IReportRow[]>([])

	useEffect(() => {
		if (fromDate === '' || toDate === '') {
			return
		}
		axios.get(`/izvestaj/za-period?fromDate=${fromDate}&toDate=${toDate}`)
			.then((response) => {
				setReport(response.data.data)
			})
			.catch((error) => {
				console.log(error)
				Modal.error({
					content: 'Greška: '+error,
				});
			})
	}, [fromDate, toDate])

	return {
		report
	}
}

export default useGetBodoviZaPeriod