import React from "react";
import {Button, Modal} from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";

type IProps = {
	action: 'save' | 'update',
	visible: boolean,
	setVisible: any,
	caseId: string|undefined,
	reloadParent: boolean,
	setReloadParent: any,
	timelineComment: string,
	setTimelineComment: any,
	timelineCommentId: number|undefined
	reloadCommentsHistory: boolean
	setReloadCommentsHistory: any
}

const ModalTimelineComment: React.FC<IProps> = ({
	                                                action, visible, setVisible, caseId, reloadParent, setReloadParent,
	                                                timelineComment, setTimelineComment, timelineCommentId,
	                                                reloadCommentsHistory, setReloadCommentsHistory}) => {
	const saveComment = (action: 'save' | 'update') => {
		if (timelineComment==='' || timelineComment === undefined) {
			Modal.error({
				content: 'Komentar je obavezan!',
				onOk() {
					return
				},
			})
			return
		}

		let data = {
			caseId  : caseId,
			comment : timelineComment,
			action  : 'comment',
			type    : 'success',
			title   : 'Komentar',
		}

		if (action === 'save') {
			axios.post(`/timeline`, data)
				.then((result) => {
					setReloadParent(!reloadParent)
					setReloadCommentsHistory(!reloadCommentsHistory)
					Modal.success({
						content: 'Uspešeno ste snimili komentar.',
						onOk() {
							setVisible(false)
						},
					});
				})
				.catch((error) => {console.log(error)})
		}
		else {
			axios.patch(`/timeline/${timelineCommentId}`, data)
				.then((result) => {
					setReloadParent(!reloadParent)
					setReloadCommentsHistory(!reloadCommentsHistory)
					Modal.success({
						content: 'Uspešeno ste izmenili komentar.',
						onOk() {
							setVisible(false)
						},
					});
				})
				.catch((error) => {console.log(error)})
		}
	}

	return(
		<Modal
			title="Komentar"
			open={visible}
			onCancel={() => setVisible(false)}
			destroyOnClose={true}
			footer={[
				<Button type="primary" key={0} onClick={() => saveComment(action)}>
					Snimi
				</Button>,
			]}>
			<TextArea
				placeholder="Unesite komentar..."
				rows={4}
				value={timelineComment}
				onChange={(e) => setTimelineComment(e.target.value)}
			/>
		</Modal>
	)
}

export default ModalTimelineComment