import {useState} from "react";

const useIsAuthed = () => {
	const [authed, setAuthed] = useState<boolean>(localStorage.getItem('authed') === 'true')

	const setAuthedHelper = (value:string|boolean) => {
		if (value === 'true' || value === true) {
			localStorage.setItem('authed', 'true')
			setAuthed(true)
		} else {
			localStorage.removeItem('authed')
		}
	}

	return {
		authed,
		setAuthedHelper
	}
}

export default useIsAuthed