import React, {useEffect} from "react";
import {Button, Form, Input} from "antd";
import ITablaColumn from "../Interfaces/ITablaColumn";

const formItemLayout = {
	labelCol: {
		xs: {
			span: 24,
		},
		sm: {
			span: 8,
		},
	},
	wrapperCol: {
		xs: {
			span: 24,
		},
		sm: {
			span: 16,
		},
	},
};

const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 16,
			offset: 8,
		},
	},
};

type IProps = {
	column: ITablaColumn,
	onFinishHandler: any
}

const ColumnEditForm: React.FC<IProps> = ({column, onFinishHandler}) => {

	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue({
			title: column.title,
			order: column.order,
			points: column.points,
		})
	}, [form, column]);

	return (
		<Form
			{...formItemLayout}
			form={form}
			name="column_edit"
			onFinish={onFinishHandler}
			scrollToFirstError
		>
			<Form.Item
				name="title"
				label="Naziv kolone"
				rules={[
					{
						required: true,
						message: 'Unesite naziv',
					},
				]}
			>
				<Input placeholder="npr. Urgencija" />
			</Form.Item>

			<Form.Item
				name="order"
				label="Pozicija"
				rules={[
					{
						required: true,
						message: 'Unesite poziciju',
					},
				]}
			>
				<Input type={"number"} disabled={true}/>
			</Form.Item>

			<Form.Item
				name="points"
				label="Vrednost bodova"
				rules={[
					{
						required: true,
						message: 'Unesite broj bodova',
					},
				]}
			>
				<Input type={"number"}/>
			</Form.Item>

			<Form.Item {...tailFormItemLayout}>
				<Button size={"large"} type="primary" htmlType="submit">
					Snimi
				</Button>
			</Form.Item>
		</Form>
	)
}

export default ColumnEditForm