import React, {useEffect, useState} from "react";
import {Content} from "antd/lib/layout/layout";
import {Breadcrumb, Col, Row} from "antd";
import DefaultLayout from "../../Components/DefaultLayout";
import { Redirect } from "react-router-dom";
import useGetCase from "../../Hooks/Cases/useGetCase";
import useIsAdmin from "../../Hooks/Users/useIsAdmin";
import useGetUserId from "../../Hooks/Users/useGetUserId";
import CaseEditForm from "../../Forms/CaseEditForm";

const CaseEdit: React.FC = (props:any) => {

	const [redirect, setRedirect] = useState(false)
	const {predmet} = useGetCase(props.match.params.id, false)
	const {isAdmin} = useIsAdmin()
	const {userId} = useGetUserId()

	useEffect(() => {
		// this is only to not load the data into the form. Redirect is below!
		if (!isAdmin && predmet && predmet.nosilac_predmeta?.id !== parseInt(userId) && predmet.mentor?.id !== parseInt(userId)) {
			return
		}
	}, [predmet, isAdmin, userId])

	if (redirect) {
		return(<Redirect to={'/predmeti'} />)
	}
	if (!isAdmin && predmet && predmet.nosilac_predmeta?.id !== parseInt(userId) && predmet.mentor?.id !== parseInt(userId)) {
		return(<Redirect to={'/forbidden'} />)
	}

	return (
		<DefaultLayout>
			<Content style={{ padding: '0 50px' }}>
				<Breadcrumb style={{ margin: '16px 0' }}>
					<Breadcrumb.Item>Početna</Breadcrumb.Item>
					<Breadcrumb.Item>Predmeti</Breadcrumb.Item>
					<Breadcrumb.Item>Ažuriranje podataka</Breadcrumb.Item>
				</Breadcrumb>
				<Row>
					<Col lg={{span: 12, offset: 6}} md={{span: 16, offset: 4}} xs={{span: 24, offset: 0}} >
						<CaseEditForm predmet={predmet} onFinishHandler={() => setRedirect(true)}/>
					</Col>
				</Row>
			</Content>
		</DefaultLayout>
	)
}

export default CaseEdit