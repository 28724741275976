import React, {useEffect, useState} from "react";
import {Button, Modal, Select, Input, Switch, Space} from "antd";
import IStatus from "../Interfaces/IStatus";
import axios from "axios";
import ICase from "../Interfaces/ICase";
import CaseEditForm from "../Forms/CaseEditForm";
import useIsAdmin from "../Hooks/Users/useIsAdmin";
import useGetUserId from "../Hooks/Users/useGetUserId";

const { Option } = Select;
const {TextArea} = Input;

type IProps = {
	predmet: ICase|undefined
	setPredmet: any,
	setReloadPredmet: any
	reloadPredmet: boolean
	reloadDashboard: boolean
	setReloadDashboard: any
}

const CaseMenu: React.FC<IProps> = (props:IProps) => {
	const [statuses, setStatuses] = useState<IStatus[]>([])
	const [modalCommentVisible, setModalCommentVisible] = useState(false)
	const [selectedStatusId, setSelectedStatusId] = useState(props.predmet?.status.id)
	const [comment, setComment] = useState<string>('')
	const [modalEditCaseVisible, setModalEditCaseVisible] = useState<boolean>(false)
	const {isAdmin} = useIsAdmin()
	const {userId} = useGetUserId()

	useEffect(() => {
		axios.get(`/statuses`)
			.then((response) => setStatuses(response.data.data))
			.catch((error) => console.log(error))
	}, []);

	const handleStatusChange = (value:any) => {
		setSelectedStatusId(value)
		setModalCommentVisible(true)
	}

	const saveNewStatus = () => {
		if (!props.predmet) return
		let prenesiBodove = false
		let statusesWhichTransferPoints = [4, 5, 6] // Statusi za koje se prenose bodovi, arhiviran, stopiran i ceka se naplata
		if (selectedStatusId && statusesWhichTransferPoints.includes(selectedStatusId)) {
			prenesiBodove = true
		}
		setModalCommentVisible(false)
		axios.post(`/cases/${props.predmet.id}/status/${selectedStatusId}`, {comment: comment, setPoints: prenesiBodove})
			.then(() => {
					props.setReloadDashboard(!props.reloadDashboard)
					props.setReloadPredmet(!props.reloadPredmet)
			})
			.catch((error) => {
				Modal.error({
					content: 'Greška: '+error,
				});
			})
	}

	const handleSetPriority = () => {
		if (!props.predmet) return
		let newPriorityState = props.predmet.priority === 'high' ? 'normal' : 'high'
		props.setPredmet({...props.predmet, priority: newPriorityState})
		axios.patch(`/cases/${props.predmet.id}`, {priority: newPriorityState})
			.then(() => {
				props.setReloadDashboard(!props.reloadDashboard)
			})
			.catch(error=> {
				console.log(error)
			})
	}

	const onCaseEditFinished = () => {
		props.setReloadPredmet(!props.reloadPredmet)
		setModalEditCaseVisible(false)
	}

	const showEditButton = () => {
		if (isAdmin || props.predmet?.nosilac_predmeta?.id === parseInt(userId) || props.predmet?.mentor?.id === parseInt(userId)) {
			return (<Button type="primary" onClick={() => setModalEditCaseVisible(true)}>Edit</Button>)
		}
	}

	return (
		<>
			<Space>
				<Select value={props.predmet?.status.id} style={{ minWidth: 200 }} onChange={handleStatusChange}>
					{
						statuses.map((status, index) => {
							return (
								<Option key={index} value={status.id}>{status.name}</Option>
							)
						})
					}
				</Select>
				<Switch
					className='priority_switch'
					checked={props.predmet?.priority === 'high'}
					onChange={handleSetPriority}
				/> Visoki prioritet
				{showEditButton()}
			</Space>


			<Modal
				title="Promena statusa"
				open={modalCommentVisible}
				onCancel={() => setModalCommentVisible(false)}
				destroyOnClose={true}
				footer={[
				<Button key={0} onClick={() => {saveNewStatus()}}>
					Snimi
				</Button>,
			]}>
				<p>Unesite komentar</p>
				<TextArea rows={4} value={comment} onChange={(e) => setComment(e.target.value)}/>
			</Modal>

			<Modal
				title="Editovanje predmeta"
				open={modalEditCaseVisible}
				onCancel={() => setModalEditCaseVisible(false)}
				destroyOnClose={true}
				width={1000}
				footer={[]}>
				<CaseEditForm predmet={props.predmet} onFinishHandler={onCaseEditFinished} />
			</Modal>
		</>
	)
}

export default CaseMenu;