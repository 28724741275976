import React, {useState} from "react";
import {colors} from "../../Hooks/Reports/reportColors";
import {DatePicker} from 'antd';
import locale from 'antd/es/date-picker/locale/sr_RS';
import {Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Cell} from 'recharts';
import useGetBodoviZaPeriod from "../../Hooks/Reports/useGetBodoviZaPeriod";

const { RangePicker } = DatePicker;

const BodoviPoMesecima: React.FC = () => {

	const [fromDate, setFromDate] = useState('')
	const [toDate, setToDate] = useState('')
	const {report} = useGetBodoviZaPeriod(fromDate, toDate)

	const onDateChanged = (dates:any, dateStrings:any) => {
		setFromDate(dateStrings[0])
		setToDate(dateStrings[1])
	}

	const getMaxPoints = () => {
		return Math.max(...report.map(o => o.points))
	}

	return (
		<>
			<RangePicker
				picker="month"
				locale={locale}
				onChange={onDateChanged}
			/>
			<ResponsiveContainer width="100%" height={700} debounce={50}>
				<BarChart width={500} height={140} data={report} layout="vertical" barCategoryGap={10}>
					<XAxis
						hide
						axisLine={true}
						type="number"
						domain={[0, getMaxPoints()]}
					/>
					<YAxis
						yAxisId={0}
						dataKey='name'
						type="category"
						axisLine={true}
						tickLine={false}
					/>
					<Tooltip
						wrapperStyle={{ width: 'auto', backgroundColor: '#ccc' }}
						formatter={function(total) {return `${total}`}}
					/>
					<Bar dataKey='points' minPointSize={2} >
						{report.map((row, idx) => {
							return <Cell  key={row['name']} fill={colors[idx]} />;
						})}
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		</>
	)
}

export default BodoviPoMesecima