import React, {useState} from "react";
import {Content} from "antd/lib/layout/layout";
import {Breadcrumb, Button, Modal, Row, Table} from "antd";
import {CheckCircleOutlined} from '@ant-design/icons';
import Title from "antd/es/typography/Title";
import DefaultLayout from "../Components/DefaultLayout";
import useGetUserId from "../Hooks/Users/useGetUserId";
import useGetNotifications from "../Hooks/Users/useGetNotifications";
import ICase from "../Interfaces/ICase";
import ModalCaseDetails from "../Components/ModalCaseDetails";
import axios from "axios";
import useGetCase from "../Hooks/Cases/useGetCase";
import {AlignType} from "rc-table/lib/interface";
import qs from "qs";
import {useDispatch} from "react-redux";
import {decrementNotificationsNo} from "../Redux/notificationsSlice";

const NotificationsPage: React.FC = () => {
	const {userId} = useGetUserId()
	const [page, setPage] = useState(1)
	const [reloadNotifications, setReloadNotifications] = useState(false)
	const {notifications, pagination} = useGetNotifications(page, reloadNotifications, userId, 0, 'case')
	const [modalVisible, setModalVisible] = useState(false)
	const [selectedPredmet, setSelectedPredmet] = useState('')
	const [reloadPredmet, setReloadPredmet] = useState(false)
	const {predmet, setPredmet} = useGetCase(selectedPredmet, reloadPredmet)
	const dispatch = useDispatch()

	const markNotificationSeen = (id: number) => {
		let data = qs.stringify({seen: 1})
		axios.patch(`/notifications/${id}`, data)
			.then(() => {
				setReloadNotifications(!reloadNotifications)
				dispatch(decrementNotificationsNo())
			})
			.catch((error) => {
				console.log(error)
				Modal.error({
					content: 'Greška: ' + error,
				});
			})
	}

	const columns = [
		{
			title: 'Sadržaj notifikacije',
			dataIndex: 'content',
			key: 'content',
			render: (text: string) => {
				return <div dangerouslySetInnerHTML={{__html: text}}/>
			}
		},
		{
			title: 'Predmet',
			dataIndex: 'case',
			key: 'case',
			render: (text: ICase) => {
				return text ? <Button type='link' onClick={() => {
					setSelectedPredmet(text.id)
					setModalVisible(true)
				}
				}>{text.naziv}</Button> : ''
			}
		},
		{
			title: 'Pregledano',
			dataIndex: 'seen',
			key: 'seen',
			align: 'center' as AlignType,
			render: (notification: any) => {
				return <Button type='link' onClick={() => markNotificationSeen(notification.id)}><CheckCircleOutlined/></Button>
			}
		},
		{
			title: 'Datum i vreme',
			dataIndex: 'created_at',
			key: 'created_at',
			render: (text: any) => {
				let date = new Date(text)
				let datePart = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
				let timePart = date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
				return datePart + ' ' + timePart
			},
		},
	];

	const handleTableChange = (pagination: any) => {
		setPage(pagination.current)
	}

	return (
		<DefaultLayout>
			<Content style={{padding: '0 50px'}}>
				<Breadcrumb style={{margin: '16px 0'}}>
					<Breadcrumb.Item>Početna</Breadcrumb.Item>
					<Breadcrumb.Item>Notifikacije</Breadcrumb.Item>
				</Breadcrumb>
				<Row>
					<Title level={3}>Notifikacije</Title>
				</Row>
				<Table columns={columns}
				       dataSource={notifications?.map((notification, index) => (
					       {
						       key: index,
						       id: notification.id,
						       content: notification.content,
						       case: notification.case,
						       seen: notification,
						       created_at: notification.created_at,
					       }))}
				       pagination={pagination}
               onChange={handleTableChange}
				/>

				<ModalCaseDetails
					reloadPredmet={reloadPredmet}
					setReloadPredmet={setReloadPredmet}
					reloadDashboard={false}
					setReloadDashboard={() => {
					}}
					predmet={predmet}
					setPredmet={setPredmet}
					visible={modalVisible}
					setTaskViewModalVisible={setModalVisible}
				/>

			</Content>
		</DefaultLayout>
	)
}

export default NotificationsPage