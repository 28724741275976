import React, {useEffect, useState} from "react";
import {Button, Modal, Select, Space, Switch} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import useUsers from "../Hooks/Users/useUsers";
import useIsAdmin from "../Hooks/Users/useIsAdmin";

const {Option} = Select

type IProps = {
	visible: boolean,
	setVisible: any,
	reloadParent: boolean,
	setReloadParent: any,
}

const ModalFilter: React.FC<IProps> = ({visible, setVisible, reloadParent, setReloadParent}) => {
	const {isAdmin} = useIsAdmin()
	const [onlyMineCases, setOnlyMineCases] = useState(false)
	const [ownerSwitch, setOwnerSwitch] = useState(false)
	const [mentorSwitch, setMentorSwitch] = useState(false)
	const [inProgress, setInProgress] = useState(false)
	const [waitingForState, setWaitingForState] = useState(false)
	const [waitingForInvestor, setWaitingForInvestor] = useState(false)
	const [waitingForGeometar, setWaitingForGeometar] = useState(false)
	const [waitingForThirdPerson, setWaitingForThirdPerson] = useState(false)
	const [waitingForPayment, setWaitingForPayment] = useState(false)
	const [caseStopped, setCaseStopped] = useState(false)
	const [arhivirani, setArhivirani] = useState(false)
	const {users} = useUsers(1, false)
	const [onlyVerified, setOnlyVerified] = useState(false)
	const [onlyUnVerified, setOnlyUnVerified] = useState(false)

	useEffect(() => {

		// Prikaz trenutnih vrednosti switch-eva:

		if (localStorage.getItem('inProgress') === 'true') {
			setInProgress(true)
		} else {
			setInProgress(false)
		}
		if (localStorage.getItem('onlyMineCases') === 'true') {
			setOnlyMineCases(true)
		} else {
			setOnlyMineCases(false)
		}
		if (localStorage.getItem('waitingForState') === 'true') {
			setWaitingForState(true)
		} else {
			setWaitingForState(false)
		}
		if (localStorage.getItem('waitingForInvestor') === 'true') {
			setWaitingForInvestor(true)
		} else {
			setWaitingForInvestor(false)
		}
		if (localStorage.getItem('waitingForPayment') === 'true') {
			setWaitingForPayment(true)
		} else {
			setWaitingForPayment(false)
		}
		if (localStorage.getItem('waitingForGeometar') === 'true') {
			setWaitingForGeometar(true)
		} else {
			setWaitingForGeometar(false)
		}
		if (localStorage.getItem('waitingForThirdPerson') === 'true') {
			setWaitingForThirdPerson(true)
		} else {
			setWaitingForThirdPerson(false)
		}
		if (localStorage.getItem('caseStopped') === 'true') {
			setCaseStopped(true)
		} else {
			setCaseStopped(false)
		}
		if (localStorage.getItem('arhivirani') === 'true') {
			setArhivirani(true)
		} else {
			setArhivirani(false)
		}
		if (localStorage.getItem('specificOwner') === 'true') {
			setOwnerSwitch(true)
		} else {
			setOwnerSwitch(false)
		}
		if (localStorage.getItem('mentorSwitch') === 'true') {
			setMentorSwitch(true)
		} else {
			setMentorSwitch(false)
		}
		if (localStorage.getItem('onlyVerified') === 'true') {
			setOnlyVerified(true)
		} else {
			setOnlyVerified(false)
		}
		if (localStorage.getItem('onlyUnVerified') === 'true') {
			setOnlyUnVerified(true)
		} else {
			setOnlyUnVerified(false)
		}
	}, []);

	const handleOnlyMineCasesChange = () => {
		let newState = onlyMineCases ? 'false' : 'true'
		setOnlyMineCases(!onlyMineCases)
		localStorage.setItem('onlyMineCases', newState)
		if (ownerSwitch) {
			setOwnerSwitch(false)
			localStorage.setItem('specificOwner', 'false')
		}
	}

	const handleInProgressChange = () => {
		let newState = inProgress ? 'false' : 'true'
		setInProgress(!inProgress)
		localStorage.setItem('inProgress', newState)
	}

	const handleWaitingForStateChange = () => {
		let newState = waitingForState ? 'false' : 'true'
		setWaitingForState(!waitingForState)
		localStorage.setItem('waitingForState', newState)
	}

	const handleWaitingForInvestorChange = () => {
		let newState = waitingForInvestor ? 'false' : 'true'
		setWaitingForInvestor(!waitingForInvestor)
		localStorage.setItem('waitingForInvestor', newState)
	}

	const handleWaitingForGeometarChange = () => {
		let newState = waitingForGeometar ? 'false' : 'true'
		setWaitingForGeometar(!waitingForGeometar)
		localStorage.setItem('waitingForGeometar', newState)
	}

	const handleWaitingForThirdPersonChange = () => {
		let newState = waitingForThirdPerson ? 'false' : 'true'
		setWaitingForThirdPerson(!waitingForThirdPerson)
		localStorage.setItem('waitingForThirdPerson', newState)
	}

	const handleWaitingForPaymentChange = () => {
		let newState = waitingForPayment ? 'false' : 'true'
		setWaitingForPayment(!waitingForPayment)
		localStorage.setItem('waitingForPayment', newState)
	}

	const handleCaseStoppedChange = () => {
		let newState = caseStopped ? 'false' : 'true'
		setCaseStopped(!caseStopped)
		localStorage.setItem('caseStopped', newState)
	}

	const handleArhiviraniChanged = () => {
		let newState = arhivirani ? 'false' : 'true'
		setArhivirani(!arhivirani)
		localStorage.setItem('arhivirani', newState)
	}

	const handleSwitchOwnerChanged = () => {
		let newState = ownerSwitch ? 'false' : 'true'
		setOwnerSwitch(!ownerSwitch)
		localStorage.setItem('specificOwner', newState)

		if (onlyMineCases) {
			setOnlyMineCases(false)
			localStorage.setItem('onlyMineCases', 'false')
		}
	}

	const handleSwitchMentorChanged = () => {
		let newState = mentorSwitch ? 'false' : 'true'
		setMentorSwitch(!mentorSwitch)
		localStorage.setItem('mentorSwitch', newState)

		// if (onlyMineCases) {
		// 	setOnlyMineCases(false)
		// 	localStorage.setItem('onlyMineCases', 'false')
		// }
	}

	const handleSpecificUserSelected = (value:any) => {
		localStorage.setItem('filterSelectedUserId', value)
	}

	const handleMentorSelected = (value:any) => {
		localStorage.setItem('filterMentorSelectedId', value)
	}

	const handleOnlyVerifiedChanged = () => {
		let newState = onlyVerified ? 'false' : 'true'
		setOnlyVerified(!onlyVerified)
		localStorage.setItem('onlyVerified', newState)
	}

	const handleOnlyUnVerifiedChanged = () => {
		let newState = onlyUnVerified ? 'false' : 'true'
		setOnlyUnVerified(!onlyUnVerified)
		localStorage.setItem('onlyUnVerified', newState)
	}

	const onModalClose = () => {
		setVisible(false)
		setReloadParent(!reloadParent)
	}

	const getSelectedUser = () => {
		let filterSelectedUserId = localStorage.getItem('filterSelectedUserId') ?? '';
		if (filterSelectedUserId !== '') {
			let selectedUser = users.filter((user) => {
				return user.id === parseInt(filterSelectedUserId)
			})
			if (selectedUser.length > 0) {
				return selectedUser[0].first_name+' '+selectedUser[0].last_name
			}
		}
		return 'Izaberite korisnika...'
	}

	const getSelectedMentor = () => {
		let filterSelectedMentorId = localStorage.getItem('filterMentorSelectedId') ?? '';
		if (filterSelectedMentorId !== '') {
			let selectedMentor = users.filter((user) => {
				return user.id === parseInt(filterSelectedMentorId)
			})
			if (selectedMentor.length > 0) {
				return selectedMentor[0].first_name+' '+selectedMentor[0].last_name
			}
		}
		return 'Izaberite korisnika...'
	}

	const adminSwitches = () => {
		if (!isAdmin) {
			return ''
		}
		return (
			<>
				<div>
					<Switch
						checkedChildren={<CheckOutlined />}
						unCheckedChildren={<CloseOutlined />}
						checked={onlyVerified}
						onChange={handleOnlyVerifiedChanged}
					/> Prikaži bodovane predmete<br />
				</div>
				<div>
					<Switch
						checkedChildren={<CheckOutlined />}
						unCheckedChildren={<CloseOutlined />}
						checked={onlyUnVerified}
						onChange={handleOnlyUnVerifiedChanged}
					/> Prikaži nebodovane predmete<br />
				</div>
			</>
		)
	}

	return(
		<Modal
			title="Filter"
			open={visible}
			onCancel={onModalClose}
			destroyOnClose={true}
			okText={'Potvrdi'}
			cancelText={'Otkaži'}
			footer={[
				<Button key={0} onClick={onModalClose}>
					Ok
				</Button>,
			]}
		>
			<Space direction="vertical">
				<div>
					<Switch
						checkedChildren={<CheckOutlined />}
						unCheckedChildren={<CloseOutlined />}
						checked={onlyMineCases}
						onChange={handleOnlyMineCasesChange}
					/> Predmeti dodeljeni meni<br />
				</div>
				<div>
					<Switch
						checkedChildren={<CheckOutlined />}
						unCheckedChildren={<CloseOutlined />}
						checked={inProgress}
						onChange={handleInProgressChange}
					/> Sa statustom "U toku..."<br />
				</div>
				<div>
					<Switch
						checkedChildren={<CheckOutlined />}
						unCheckedChildren={<CloseOutlined />}
						checked={waitingForState}
						onChange={handleWaitingForStateChange}
					/> Sa statustom "Čeka se državni organ"<br />
				</div>
				<div>
					<Switch
						checkedChildren={<CheckOutlined />}
						unCheckedChildren={<CloseOutlined />}
						checked={waitingForInvestor}
						onChange={handleWaitingForInvestorChange}
					/> Sa statustom "Čeka se investitor"<br />
				</div>
				<div>
					<Switch
						checkedChildren={<CheckOutlined />}
						unCheckedChildren={<CloseOutlined />}
						checked={waitingForGeometar}
						onChange={handleWaitingForGeometarChange}
					/> Sa statustom "Čeka se geometar"<br />
				</div>
				<div>
					<Switch
						checkedChildren={<CheckOutlined />}
						unCheckedChildren={<CloseOutlined />}
						checked={waitingForThirdPerson}
						onChange={handleWaitingForThirdPersonChange}
					/> Sa statustom "Čeka se treće lice"<br />
				</div>
				<div>
					<Switch
						checkedChildren={<CheckOutlined />}
						unCheckedChildren={<CloseOutlined />}
						checked={waitingForPayment}
						onChange={handleWaitingForPaymentChange}
					/> Sa statustom "Čeka se naplata"<br />
				</div>
				<div>
					<Switch
						checkedChildren={<CheckOutlined />}
						unCheckedChildren={<CloseOutlined />}
						checked={caseStopped}
						onChange={handleCaseStoppedChange}
					/> Sa statustom "Stopiran do daljnjeg"<br />
				</div>
				<div>
					<Switch
						checkedChildren={<CheckOutlined />}
						unCheckedChildren={<CloseOutlined />}
						checked={arhivirani}
						onChange={handleArhiviraniChanged}
					/> Arhivirani<br />
				</div>
				<div>
						<Switch
							checkedChildren={<CheckOutlined />}
							unCheckedChildren={<CloseOutlined />}
							checked={ownerSwitch}
							onChange={handleSwitchOwnerChanged}
							/> Predmeti dodeljeni korisniku: &nbsp;
								<Select
									style={{width:'220px'}}
									onChange={handleSpecificUserSelected}
									defaultValue={getSelectedUser()}
									disabled={!ownerSwitch}
								>
									{users.map((user, index) => {
										return (
											<Option key={index} value={user.id}>{user.first_name+' '+user.last_name}</Option>
										)
									})}
								</Select> <br />
				</div>
				<div>
						<Switch
							checkedChildren={<CheckOutlined />}
							unCheckedChildren={<CloseOutlined />}
							checked={mentorSwitch}
							onChange={handleSwitchMentorChanged}
							/> Predmeti dodeljeni mentoru: &nbsp;
								<Select
									style={{width:'220px'}}
									onChange={handleMentorSelected}
									defaultValue={getSelectedMentor()}
									disabled={!mentorSwitch}
								>
									{users.map((user, index) => {
										return (
											<Option key={index} value={user.id}>{user.first_name+' '+user.last_name}</Option>
										)
									})}
								</Select> <br />
				</div>

				{adminSwitches()}

			</Space>
		</Modal>
	)
}

export default ModalFilter