import React, {useEffect, useState} from "react";
import {Button, Divider, Form, Input, Modal, Select, Checkbox} from "antd";
import axios from "axios";
import ICase from "../Interfaces/ICase";
import useUsers from "../Hooks/Users/useUsers";
import useSearchInvestors from "../Hooks/Investors/useSearchInvestors";
import Title from "antd/es/typography/Title";
import styled from "styled-components";
import useIsAdmin from "../Hooks/Users/useIsAdmin";
import CasePointFormItem from "./CasePointFormItem";
import ICasePoint from "../Interfaces/ICasePoint";

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
	labelCol: {
		xs: {
			span: 24,
		},
		sm: {
			span: 8,
		},
	},
	wrapperCol: {
		xs: {
			span: 24,
		},
		sm: {
			span: 16,
		},
	},
};

const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 16,
			offset: 8,
		},
	},
};

type IProps = {
	predmet?: ICase,
	onFinishHandler: any
}

const CaseEditForm: React.FC<IProps> = ({predmet, onFinishHandler}) => {

	const [form] = Form.useForm()
	const {users} = useUsers(1, false)
	const [autocompleteSearchValue, setAutocompleteSearchValue] = useState<string>('')
	const {investors} = useSearchInvestors(autocompleteSearchValue)
	const {isAdmin} = useIsAdmin()

	useEffect(() => {
		form.setFieldsValue({
			br_predmeta: predmet?.br_predmeta,
			naziv: predmet?.naziv,
			br_kat_parcele: predmet?.br_kat_parcele,
			br_kat_parcele_stari: predmet?.br_kat_parcele_stari,
			kat_opstina: predmet?.kat_opstina,
			opstina: predmet?.opstina,
			adresa: predmet?.adresa,
			napomena: predmet?.napomena,
			opis: predmet?.opis,
			obrazlozenje: predmet?.obrazlozenje,
			geometar: predmet?.geometar,
			ugovorena_cena: predmet?.ugovorena_cena,
			datum_primanja: predmet?.datum_primanja,
			nosilac_predmeta_id: predmet?.nosilac_predmeta?.id,
			mentor_id: predmet?.mentor?.id,
			mentor_multiplier: predmet?.mentor_multiplier,
			investor_id: predmet?.investor.id,
			created_at: predmet?.created_at,
			updated_at: predmet?.updated_at,
			payments: [],
			verified: predmet?.verified,
		})
		// Set points
		if (predmet?.points) {
			// @ts-ignore
			let sortedPoints = predmet?.points.sort((a, b) => (parseInt(a.column?.order) > parseInt(b.column?.order)) ? 1 : -1)
			sortedPoints?.forEach((point: ICasePoint) => {
				form.setFieldsValue({
					points: {
						[point.column_id] : point.points
					}
				})
			})
		}
	}, [predmet, form]);


	const FormTitle = styled.div`
		text-align: center;
		margin-bottom: 30px;
	`

	const casePointsFieldset = (points: ICasePoint[]|undefined) => {
		if (points === undefined) return
		return (
			<>
				<Divider />
				<FormTitle>
					<Title level={3}>Bodovanje kolona</Title>
				</FormTitle>
				{
					points.map((pointData, index) => {
						return <CasePointFormItem key={index} casePoint={pointData}/>
					})
				}
			</>
		)
	}

	const caseVerifiedFormItem = () => {

		return (
			<Form.Item
				name="verified"
				label="Bodovi za predmet verifikovani"
				valuePropName="checked"
			>
				<Checkbox />
			</Form.Item>
		)
	}

	const onFinish = async (formValues:any) => {
		if (!predmet) return
		try {
			await axios.patch(`/cases/${predmet.id}`, formValues)
			Modal.success({
				content: 'Predmet je uspešno ažuriran',
				onOk() {
					onFinishHandler()
				},
			});
		} catch(error) {
			console.log(error)
			Modal.error({
				content: 'Greška prilikom snimanja predmeta u bazu: '+error,
			});
		}
	};

	const handleOnSearch = (value:string) => {
		setAutocompleteSearchValue(value)
	}

	return (
		<>
			<FormTitle>
				<Title level={3}>Editovanje predmeta</Title>
			</FormTitle>
			<Form
				{...formItemLayout}
				form={form}
				name="create"
				onFinish={onFinish}
				scrollToFirstError
			>
				<Form.Item
					name="br_predmeta"
					label="Broj predmeta"
					rules={[
						{
							type: 'string',
							message: 'Unos moze biti kombinacija slova i brojeva',
						},
						{
							required: true,
							message: 'Unesite broj predmeta',
						},
					]}
				>
					<Input placeholder="npr. 11/2021" />
				</Form.Item>

				<Form.Item
					name="naziv"
					label="Naziv predmeta"
					rules={[
						{
							type: 'string',
							message: 'Unos moze biti kombinacija slova i brojeva',
						},
						{
							required: true,
							message: 'Unesite naziv predmeta',
						},
					]}
				>
					<Input placeholder="npr. Proširenje magacina" />
				</Form.Item>

				<Form.Item
					name="br_kat_parcele"
					label="Broj katastarske parcele"
					rules={[
						{
							type: 'string',
							message: 'Unos moze biti kombinacija slova i brojeva',
						},
						{
							required: true,
							message: 'Unesite broj katastarske parcele',
						},
					]}
				>
					<Input placeholder="npr. 1234/13" />
				</Form.Item>

				<Form.Item
					name="br_kat_parcele_stari"
					label="Stari broj katastarske parcele"
					rules={[
						{
							type: 'string',
							message: 'Unos moze biti kombinacija slova i brojeva',
						},
					]}
				>
					<Input placeholder="npr. 1522/01" />
				</Form.Item>

				<Form.Item
					name="kat_opstina"
					label="Katastarska opština"
					rules={[
						{
							required: true,
							message: 'Unesite Katastarsku opštinu',
						},
						{
							type: 'string',
							message: 'Unos moze biti kombinacija slova',
						},
					]}
				>
					<Input
						style={{
							width: '100%',
						}}
						placeholder="npr. Šabac"
					/>
				</Form.Item>

				<Form.Item
					name="opstina"
					label="Administrativna opština"
					rules={[
						{
							type: 'string',
							message: 'Unos moze biti kombinacija slova',
						},
					]}
					hasFeedback
				>
					<Input placeholder="npr. Šabac" />
				</Form.Item>

				<Form.Item
					name="adresa"
					label="Adresa"
					rules={[
						{
							type: 'string',
							message: 'Unos moze biti kombinacija slova i brojeva',
						},
					]}
					hasFeedback
				>
					<Input placeholder="npr. Ulica 11" />
				</Form.Item>

				<Form.Item
					name="napomena"
					label="Napomena"
					rules={[
						{
							type: 'string',
							message: 'Unos moze biti kombinacija slova i brojeva',
						},
					]}
					hasFeedback
				>
					<TextArea rows={4} />
				</Form.Item>

				<Form.Item
					name="opis"
					label="Opis"
					rules={[
						{
							type: 'string',
							message: 'Unos moze biti kombinacija slova i brojeva',
						},
						{
							required: true,
							message: 'Unesite opis predmeta',
						},
					]}
					hasFeedback
				>
					<Input placeholder="npr. Legalizacija kuće" />
				</Form.Item>

				<Form.Item
					style={isAdmin ? {} : {display:"none"}}
					name="obrazlozenje"
					label="Finansije"
					rules={[
						{
							type: 'string',
							message: 'Unos moze biti kombinacija slova i brojeva',
						},
					]}
					hasFeedback
				>
					<TextArea rows={4} />
				</Form.Item>

				<Form.Item
					name="geometar"
					label="Geometar"
					rules={[
						{
							type: 'string',
							message: 'Unos moze biti kombinacija slova i brojeva',
						},
					]}
					hasFeedback
				>
					<Input placeholder="npr.Petar Petrović" />
				</Form.Item>

				{
					isAdmin ?
						<Form.Item
							name="ugovorena_cena"
							label="Ugovorena cena"
							rules={[
								{
									pattern: new RegExp(/^[0-9]+$/),
									message: 'Unos moze biti samo ceo broj',
								},
							]}
							hasFeedback
						>
							<Input placeholder="npr. 150000" />
						</Form.Item>
						: ''
				}

				<Form.Item
					name="nosilac_predmeta_id"
					label="Nosilac predmeta"
					rules={[
						{
							required: true,
							message: 'Unesite nosioca premeta',
						},
					]}
				>
					<Select placeholder="Izaberite nosioca predmeta...">
						{
							users.map((user, index) => {
								return (
									<Option key={index} value={user.id}>{user.first_name + ' ' + user.last_name}</Option>
								)
							})
						}
					</Select>
				</Form.Item>

				<Form.Item
					name="mentor_id"
					label="Mentor"
					rules={[
						{
							required: false,
							message: 'Izaberite mentora premeta',
						},
					]}
				>
					<Select placeholder="Izaberite mentora predmeta...">
						{
							users.map((user, index) => {
								return (
									<Option key={index} value={user.id}>{user.first_name + ' ' + user.last_name}</Option>
								)
							})
						}
					</Select>
				</Form.Item>

				{
					isAdmin
						? <Form.Item
								name="mentor_multiplier"
								label="Koeficijent za mentora"
								rules={[
									{
										pattern: new RegExp(/^[0-9\\.]+$/),
										message: 'Unos moze biti samo broj',
									},
								]}
								hasFeedback
							>
								<Input placeholder="npr. 0.2" />
							</Form.Item>
						: ''
				}

				<Form.Item
					name="investor_id"
					label="Investitor"
					rules={[
						{
							required: true,
							message: 'Unesite investitora',
						},
					]}
				>
					<Select
						placeholder='Izaberite investitora...'
						showSearch
						onSearch={handleOnSearch}
						filterOption={false}
						// onChange={handleOnSearch} // mislim da nista ovde ne treba kada se selektuje investitor?!
						value={autocompleteSearchValue}
					>
						{
							investors.map((investor, index) => {
								return (
									<Option key={index} value={investor.id}>{investor.first_name + ' ' + investor.last_name + ' ' + investor.company_name}</Option>
								)})
						}
					</Select>
				</Form.Item>

				{isAdmin ? casePointsFieldset(predmet?.points) : ''}

				{isAdmin ? caseVerifiedFormItem() : ''}

				<Form.Item {...tailFormItemLayout}>
					<Button size={"large"} type="primary" htmlType="submit">
						Snimi
					</Button>
				</Form.Item>
			</Form>
		</>

	)
}

export default CaseEditForm