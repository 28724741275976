import {useEffect, useState} from "react";
import axios from "axios";
import {Modal} from "antd";
import ICase from "../../Interfaces/ICase";

const useGetCase = (caseId: string, reload:boolean) => {
	const [predmet, setPredmet] = useState<ICase>()

	useEffect(() => {
		if (!caseId || caseId === '0' || caseId === '') return
		axios.get(`/cases/${caseId}`)
			.then((response) => {
				setPredmet(response.data.data)
			})
			.catch((error) => {
				console.log(error)
				Modal.error({
					content: 'Greška: '+error,
				});
			})
	}, [caseId, reload])

	return {
		predmet,
		setPredmet,
	}
}

export default useGetCase