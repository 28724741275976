import React from "react";
import {Button, Col, Form, Input, Modal, Row, Typography} from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import styled from "styled-components";
import axios from "axios";
import useIsAdmin from "../Hooks/Users/useIsAdmin";

const { Text } = Typography;
const FormContainer = styled.div`
	background: white;
	padding: 30px;
	border-radius: 5px;
	opacity: 0.9;
	box-shadow: 5px 5px 5px grey;
`

const FormFooter = styled.div`
	text-align: center;
`

const Login: React.FC = () => {
	const {setIsAdmin} = useIsAdmin()

	const onFinish = (formValues: any) => {
		axios.post(`/login`, formValues)
			.then((response) => {
				if (response.status === 200 && response.data.token && response.data.user_id) {
					localStorage.setItem('authed', 'true')
					localStorage.setItem('user_id', response.data.user_id)
					localStorage.setItem('user_name', response.data.first_name + ' ' + response.data.last_name)
					setIsAdmin(response.data.isAdmin)
					window.location.href = "/"; // force App reload to fetch authed variable from localStorage
				} else {
					Modal.error({
						content: 'Greška: ' + response,
					});
				}
			})
			.catch((error) => {
				console.log(error)
				if (error.response.status === 401) {
					Modal.error({
						content: 'Podaci za pristup nisu validni',
					});
				} else {
					Modal.error({
						content: 'Greška: ' + error,
					});
				}
			})
	};

	return (
		// eslint-disable-next-line react/jsx-no-undef
		<Row justify="space-around" align="middle" className={'form-container'}>
			<Col span={6}>
				<FormContainer>
					<Form
						name="normal_login"
						className="login-form"
						initialValues={{ remember: true }}
						onFinish={onFinish}
					>
						<Form.Item
							name="email"
							rules={[{ required: true, message: 'Unesite email!' }]}
						>
							<Input
								prefix={<UserOutlined className="site-form-item-icon" />}
								placeholder="Email"
								size={'large'}
							/>
						</Form.Item>
						<Form.Item
							name="password"
							rules={[{ required: true, message: 'Unesite lozinku!' }]}
						>
							<Input
								prefix={<LockOutlined className="site-form-item-icon" />}
								type="password"
								placeholder="Lozinka"
								size={'large'}
							/>
						</Form.Item>
						<Form.Item>
							<Button type='link' className="login-form-forgot">
								Zaboravljena lozinka?
							</Button>
						</Form.Item>

						<Form.Item>
							<Button type="primary" htmlType="submit" className="login-form-button" size={'large'}>
								Prijavi se
							</Button>
						</Form.Item>
						<Form.Item>
							<FormFooter>
								<Text type={"secondary"}>GD Knez doo, VI puka 54, 15000 Šabac</Text>
							</FormFooter>
						</Form.Item>
					</Form>
				</FormContainer>

			</Col>
		</Row>
	);
}

export default Login