import {useEffect, useState} from "react";
import INotification from "../../Interfaces/INotification";
import axios from "axios";
import IAntdPagination from "../../Interfaces/IAntdPagination";
import {Modal} from "antd";

const useGetNotifications = (
	page: number,
	reload: boolean,
	userId: string|undefined,
	seen: number|undefined,
	include: string|undefined
) => {
	const [notifications, setNotifications] = useState<INotification[]>([])
	const [pagination, setPagination] = useState<IAntdPagination>()

	useEffect(() => {
		let url = '/notifications'
		let getQueryStringArr = []
		if (page !== 1) {
			getQueryStringArr.push('page='+page)
		}
		if (userId !== undefined) {
			getQueryStringArr.push('user_id='+userId)
		}
		if (seen !== undefined) {
			getQueryStringArr.push('seen='+seen)
		}
		if (include !== undefined) {
			getQueryStringArr.push('include='+include)
		}
		let getQueryString = getQueryStringArr.join('&')
		if (getQueryString.length > 0) {
			url = url + '?' + getQueryString
		}
		axios.get(url)
			.then((response) => {
				setNotifications(response.data.data)
				const pagination: IAntdPagination = {
					total: response.data.meta.total,
					pageSize: response.data.meta.per_page,
					current: response.data.meta.current_page,
				}
				setPagination(pagination)
			})
			.catch((error) => {
				console.log(error)
				Modal.error({
					content: 'Greška: '+error,
				});
			})
	}, [page, reload, seen, userId, include])

	return {
		notifications,
		pagination
	}
}

export default useGetNotifications