import React from "react";
import {Button, Col, Divider, Modal, Row, Tabs} from "antd";
import CaseMenu from "./CaseMenu";
import CaseDetails from "./CaseDetails";
import CaseTimeline from "./CaseTimeline";
import ICase from "../Interfaces/ICase";
import PaymentsTimeline from "./PaymentsTimeline";
import {FieldTimeOutlined, EuroOutlined} from '@ant-design/icons';
import useIsAdmin from "../Hooks/Users/useIsAdmin";

const { TabPane } = Tabs;

type IProps = {
	visible: boolean,
	setTaskViewModalVisible: any,
	predmet: ICase|undefined,
	setPredmet: any,
	setReloadPredmet: any,
	reloadPredmet: boolean
	reloadDashboard: boolean,
	setReloadDashboard: any,
}

const ModalCaseDetails: React.FC<IProps> = (props:IProps) => {

	const {isAdmin} = useIsAdmin()

	const uplateTimeLine = (predmet:ICase|undefined) => {
		if (isAdmin) {
			return (
				<TabPane
					tab={
						<span>
				          <EuroOutlined />
				          Uplate
				        </span>
					}
					key="2"
				>
					<PaymentsTimeline
						payments={props.predmet?.payments}
						ugovorena_cena={props.predmet?.ugovorena_cena ?? 0}
						caseId={props.predmet?.id}
						reloadPredmet={props.reloadPredmet}
						setReloadPredmet={props.setReloadPredmet}
					/>
				</TabPane>
			)
		}
	}

	return (
		<Modal
			title="Detalji predmeta"
			open={props.visible}
			onCancel={() => props.setTaskViewModalVisible(false)}
			width={1000}
			destroyOnClose={true}
			footer={[
				<Button key={0} onClick={() => {props.setTaskViewModalVisible(false)}}>
					Zatvori
				</Button>,
			]}
		>
			<CaseMenu
				predmet={props.predmet}
				setPredmet={props.setPredmet}
				setReloadPredmet={props.setReloadPredmet}
				reloadPredmet={props.reloadPredmet}
				reloadDashboard={props.reloadDashboard}
				setReloadDashboard={props.setReloadDashboard}
			/>
			<Divider />
			<Row>
				<Col span={14}>
					<CaseDetails predmet={props.predmet} />
				</Col>
				<Col span={10}>
					<Tabs defaultActiveKey="1">
						<TabPane
							tab={
								<span>
                  <FieldTimeOutlined />
                    Vremenska linija
                </span>
							}
							key="1"
						>
							<CaseTimeline
								timeline={props.predmet?.timeline}
								caseId={props.predmet?.id}
								className={'test'}
								setReloadPredmet={props.setReloadPredmet}
								reloadPredmet={props.reloadPredmet}
							/>
						</TabPane>
						{uplateTimeLine(props.predmet)}
					</Tabs>
				</Col>
			</Row>
		</Modal>
	)
}

export default ModalCaseDetails