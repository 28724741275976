import React, {useState} from "react";
import ITablaColumn from "../Interfaces/ITablaColumn";
import ITablaTask from "../Interfaces/ITablaTask";
import Task from "../Components/Task";
import styled from "styled-components";
import {Droppable} from "react-beautiful-dnd";
import {PlusOutlined} from '@ant-design/icons';
import axios from "axios";
import useIsAdmin from "../Hooks/Users/useIsAdmin";
import useIsAuthed from "../Hooks/Users/useIsAuthed";
import {useHistory} from "react-router-dom";
import ModalColumnSettings from "./ModalColumnSettings";

const Container = styled.div`
	margin:3px;
	border: 1px solid lightgrey;
	border-radius: 4px;
	width: 100%;
	display: flex;
	flex-direction: column;
`
const Title = styled.h5`
	padding: 5px;
	text-align: center;
`

const Settings = styled.span`
	float:left;
	cursor:pointer;
`
const AddNewTask = styled.span`
	float:right;
	cursor:pointer;
`

const TaskList = styled.div`
	padding: 5px;
	background: ${(props:{isDraggingOver: boolean}) => (props.isDraggingOver ? 'lightGrey' : 'white')};
	min-height: 200px;
	height: 100%;
`

type props = {
	column: ITablaColumn,
	tasks: ITablaTask[],
	setDisplayModal: any,
	handleClickOnTask: any,
	reloadParent: boolean,
	setReloadParent: any
}

const Column: React.FC<props> = ({column, tasks, setDisplayModal, handleClickOnTask, reloadParent, setReloadParent}) => {

	const {isAdmin} = useIsAdmin()
	const {setAuthedHelper} = useIsAuthed()
	const history = useHistory()
	const [modalSettingsVisible, setModalSettingsVisible] = useState<boolean>(false)

	const addNewTask = () => {
		setDisplayModal(column.id)
	}

	const filterTasks = () => {
		// Ovo je logika koja se izvršava za SVAKI PREDMET ciji je izlaz da li ce se predmet prikazati na osnovu
		// filtera ili ne.
		return tasks.map((task, index) => {
			let user_id = localStorage.getItem('user_id')
			if (user_id === null || user_id === undefined) {
				setAuthedHelper(false)
				localStorage.setItem('authed', 'false')
				localStorage.removeItem('isAdmin')
				history.push('/login')
				axios.post('logout')
					.then(() => {
						//nothing here
					})
					.catch((error) => {
						console.log(error)
					})
				return false
			}
			let onlyMineCasesFilter = localStorage.getItem('onlyMineCases')
			let specificOwner = localStorage.getItem('specificOwner')
			let inProgress = localStorage.getItem('inProgress')
			let waitingForState = localStorage.getItem('waitingForState')
			let waitingForInvestor = localStorage.getItem('waitingForInvestor')
			let waitingForGeometar = localStorage.getItem('waitingForGeometar')
			let waitingForThirdPerson = localStorage.getItem('waitingForThirdPerson')
			let waitingForPayment = localStorage.getItem('waitingForPayment')
			let caseStopped = localStorage.getItem('caseStopped')
			let arhivirani = localStorage.getItem('arhivirani')
			let mentorSwitch = localStorage.getItem('mentorSwitch')
			let filterSelectedUserId = localStorage.getItem('filterSelectedUserId') ?? ''
			let filterMentorSelectedId = localStorage.getItem('filterMentorSelectedId') ?? ''
			let onlyVerified = localStorage.getItem('onlyVerified') ?? ''
			let onlyUnVerified = localStorage.getItem('onlyUnVerified') ?? ''
			if (onlyMineCasesFilter === 'true' || specificOwner === 'true') {
				let userForFiltering = onlyMineCasesFilter === 'true' ? user_id : filterSelectedUserId
				if (
					(task.task_owner !== parseInt(userForFiltering) || (inProgress === 'false' && task.status_id === 1))
					|| (task.task_owner !== parseInt(userForFiltering) || (waitingForState === 'false' && task.status_id === 2))
					|| (task.task_owner !== parseInt(userForFiltering) || (waitingForInvestor === 'false' && task.status_id === 3))
					|| (task.task_owner !== parseInt(userForFiltering) || (waitingForGeometar === 'false' && task.status_id === 7))
					|| (task.task_owner !== parseInt(userForFiltering) || (waitingForThirdPerson === 'false' && task.status_id === 8))
					|| (task.task_owner !== parseInt(userForFiltering) || (waitingForPayment === 'false' && task.status_id === 4))
					|| (task.task_owner !== parseInt(userForFiltering) || (caseStopped === 'false' && task.status_id === 5))
					|| (task.task_owner !== parseInt(userForFiltering) || (arhivirani === 'false' && task.status_id === 6))
					|| (task.task_owner !== parseInt(userForFiltering) || (onlyVerified === 'false' && task.verified))
					|| (task.task_owner !== parseInt(userForFiltering) || (onlyUnVerified === 'false' && !task.verified))
				) {
					return ''
				}
			} else {
				if (
					(inProgress === 'false' && task.status_id === 1)
					|| (waitingForState === 'false' && task.status_id === 2)
					|| (waitingForInvestor === 'false' && task.status_id === 3)
					|| (waitingForGeometar === 'false' && task.status_id === 7)
					|| (waitingForThirdPerson === 'false' && task.status_id === 8)
					|| (waitingForPayment === 'false' && task.status_id === 4)
					|| (caseStopped === 'false' && task.status_id === 5)
					|| (arhivirani === 'false' && task.status_id === 6)
					|| (onlyVerified === 'false' && task.verified)
					|| (onlyUnVerified === 'false' && !task.verified)
				) {
					return ''
				}
			}

			if (mentorSwitch === 'true' && task.mentor_id !== parseInt(filterMentorSelectedId))
			{
				return ''
			}

			return <Task
				key={task.id}
				case_id={task.id}
				case_title={task.case_title}
				investor={task.investor}
				index={index}
				task_owner={task.task_owner}
				status_id={task.status_id}
				priority={task.priority}
				verified={task.verified}
				handleClickOnTask={handleClickOnTask}
			/>
		})
	}

	const onClickSettingsDots = () => {
		setModalSettingsVisible(true)
		console.log(column)
	}

	return (
		<Container>
			<Title>
				{
					isAdmin ?
						<Settings onClick={onClickSettingsDots}>
							...
						</Settings>
						: ''
				}
				{column.title}
				<AddNewTask onClick={addNewTask}>
					<PlusOutlined />
				</AddNewTask>
			</Title>

			<Droppable droppableId={column.id}>
				{(provided, snapshot) => (
					<TaskList ref={provided.innerRef} {...provided.droppableProps} isDraggingOver={snapshot.isDraggingOver}>
						{filterTasks()}
						{provided.placeholder}
					</TaskList>
				)}
			</Droppable>

			<ModalColumnSettings
				column={column}
				visible={modalSettingsVisible}
				setVisible={setModalSettingsVisible}
				reloadParent={reloadParent}
				setReloadParent={setReloadParent}
			/>
		</Container>
	)
}

export default Column;