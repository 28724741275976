import React from "react";
import {Content} from "antd/lib/layout/layout";
import {Breadcrumb, Row, Col, Card, Tabs} from "antd";
import DefaultLayout from "../../Components/DefaultLayout";
import BodoviPoMesecima from "./BodoviPoMesecima";
import Sumarni from "./Sumarni";
import BodoviZaPeriod from "./BodoviZaPeriod";

const Izvestaji: React.FC = () => {

	const items = [
		{ label: 'Broj predmeta po statusima', key: 'sumarni', children: <Sumarni /> },
		{ label: 'Bodovi po mesecima', key: 'bodovi', children: <BodoviPoMesecima /> },
		{ label: 'Bodovi za period', key: 'zaPeriod', children: <BodoviZaPeriod /> },
	];

	return (
		<DefaultLayout>
			<Content style={{ padding: '0 50px' }}>
				<Breadcrumb style={{ margin: '16px 0' }}>
					<Breadcrumb.Item>Početna</Breadcrumb.Item>
					<Breadcrumb.Item>Izveštaji</Breadcrumb.Item>
				</Breadcrumb>

				<Row gutter={16}>
					<Col span={24}>
						<div className="site-card-border-less-wrapper">
							<Card bordered={false} style={{textAlign: 'center'}}>
								<Tabs tabPosition='left' items={items} />
							</Card>
						</div>
					</Col>
				</Row>

			</Content>
		</DefaultLayout>
		)
}

export default Izvestaji