import { createSlice } from '@reduxjs/toolkit'

export const notificationsSlice = createSlice({
	name: 'notifications',
	initialState: {
		value: 0
	},
	reducers: {
		incrementNotificationsNo: state => {
			// Redux Toolkit allows us to write "mutating" logic in reducers. It
			// doesn't actually mutate the state because it uses the Immer library,
			// which detects changes to a "draft state" and produces a brand new
			// immutable state based off those changes
			state.value += 1
		},
		decrementNotificationsNo: state => {
			state.value -= 1
		},
		setNotificationsNo: (state, action) => {
			state.value = action.payload
		}
	}
})

// Action creators are generated for each case reducer function
export const { incrementNotificationsNo, decrementNotificationsNo, setNotificationsNo } = notificationsSlice.actions

export default notificationsSlice.reducer