import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Dropdown, Menu, Modal, Select, Space} from "antd";
import {Content} from "antd/lib/layout/layout";
import initialData from "../initialData";
import Column from "../Components/Column";
import ITabla from "../Interfaces/ITabla";
import {DragDropContext} from "react-beautiful-dnd";
import styled from "styled-components";
import {FilterOutlined} from "@ant-design/icons";
import DefaultLayout from "../Components/DefaultLayout";
import axios from "axios";
import NewTaskForm from "../Forms/NewTaskForm";
import useGetDashboardsForDropDown from "../Hooks/Dashboards/useGetDashboardsForDropDown";
import ModalCaseDetails from "../Components/ModalCaseDetails";
import useGetCase from "../Hooks/Cases/useGetCase";
import ModalFilter from "../Components/ModalFilter";
import useSearchCases from "../Hooks/Cases/useSearchCases";
const {Option} = Select;

const Container = styled.div`
	display:flex;
	align-items: stretch;
	min-height: 75vh;
`

const BreadcrumbContainer = styled.div`
	display: flex;
	justify-content: space-between;
`

const Dashboard:React.FC = () => {

	const [reload, setReload] = useState(false)
	const [tabla, setTabla] = useState<ITabla>(initialData);
	const [modalNewCaseVisible, setModalNewCaseVisible] = useState(false)
	const [confirmLoading] = useState(false)
	const [columnIdForNewTask, setColumnIdForNewTask] = useState('')
	const [taskIdForViewModal, setTaskIdForViewModal] = useState('')
	const [taskViewModalVisible, setTaskViewModalVisible] = useState(false)
	const [reloadPredmet, setReloadPredmet] = useState(false)
	const {predmet, setPredmet} = useGetCase(taskIdForViewModal, reloadPredmet)
	const {dashboards} = useGetDashboardsForDropDown(false)
	const [modalFilterVisible, setModalFilterVisible] = useState<boolean>(false)
	const [autocompleteSearchValue, setAutocompleteSearchValue] = useState<string>('')
	const {searchedCases} = useSearchCases(autocompleteSearchValue)

	useEffect(() => {
		let tablaId = localStorage.getItem('tablaId') ?? 1;
		axios.get(`/table/${tablaId}`)
			.then(response => {
				setTabla(response.data.data)
			})
			.catch(error => {
				console.log(error)
			})
	}, [reload]);

	const handleChangeAutocomplete = (value:any) => {
		setTaskIdForViewModal(value)
		setTaskViewModalVisible(true)
	}

	const handleOnSearch = (value:string) => {
		setAutocompleteSearchValue(value)
	}

	const onDragEnd = (result: any) => {
		const {destination, source, draggableId} = result;

		if (!destination) {
			return
		}
		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		let newTabla: ITabla;
		const start = tabla.columns[source.droppableId]
		const finish = tabla.columns[destination.droppableId]

		if (start === finish) {
			// Moving tasks inside one column, draggableId - id of task we move
			const newTaskIds = Array.from(start.taskIds);
			newTaskIds.splice(source.index, 1)
			newTaskIds.splice(destination.index, 0, draggableId)

			const newColumn = {
				...start,
				taskIds: newTaskIds
			}
			console.log(newColumn)

			newTabla = {
				...tabla,
				columns: {
					...tabla.columns,
					[newColumn.id]: newColumn
				}
			}
			setTabla(newTabla);

			axios.post(`/columns/${newColumn.id}/tasks`, {taskIds: newColumn.taskIds, newTaskId: draggableId})
				.then()
				.catch(error => console.log(error))

		} else {
			// Moving tasks between columns, draggableId - id of task we move
			const startTaskIds = Array.from(start.taskIds);
			startTaskIds.splice(source.index, 1);

			const newStartColumn = {
				...start,
				taskIds: startTaskIds
			}

			const finishTaskIds = Array.from(finish.taskIds);
			finishTaskIds.splice(destination.index, 0, draggableId);
			const newFinishColumn = {
				...finish,
				taskIds: finishTaskIds
			}

			newTabla = {
				...tabla,
				columns: {
					...tabla.columns,
					[newStartColumn.id]: newStartColumn,
					[newFinishColumn.id]: newFinishColumn,
				}
			}
			setTabla(newTabla);
			// This saves previous column tasks
			axios.post(`/columns/${newStartColumn.id}/tasks`, {taskIds: newStartColumn.taskIds})
				.then()
				.catch(error => console.log(error))
			// This saves new column tasks
			axios.post(`/columns/${newFinishColumn.id}/tasks`,
					{
						taskIds: newFinishColumn.taskIds,
						newTaskId: draggableId,
						previousColumnId: newStartColumn.id
					})
				.then()
				.catch(error => console.log(error))
		}
	}

	const setDisplayModal = (columnId: string) => {
		setColumnIdForNewTask(columnId)
		setModalNewCaseVisible(true)
	}

	const handleNewTaskOk = () => {
		setReload(!reload)
		setModalNewCaseVisible(false)
	}

	const handleClickOnTask = (taskId: string) => {
		// setReloadPredmet(!reloadPredmet)
		setTaskIdForViewModal(taskId)
		setTaskViewModalVisible(true)
	}

	const handleChangeDashboard = (e:any) => {
		localStorage.setItem('tablaId', e.key.toString())
		setReload(!reload)
	}

	const dashboardsMenu = (
		<Menu onClick={handleChangeDashboard}>
			{
				dashboards?.map((dashboard) => {
					return (
						<Menu.Item key={dashboard.id}>
							{dashboard.title}
						</Menu.Item>
					)
				})
			}
		</Menu>
	);


	return (
		<DefaultLayout>
			<Content style={{ padding: '0 50px' }}>
				<BreadcrumbContainer>
					<Breadcrumb style={{ margin: '16px 0' }}>
						<Breadcrumb.Item>Početna</Breadcrumb.Item>
						<Breadcrumb.Item>Izabrana tabla</Breadcrumb.Item>
					</Breadcrumb>
					<Dropdown className='mt13' overlay={dashboardsMenu} placement="bottomLeft" arrow>
						<Button><strong>{tabla.title}</strong></Button>
					</Dropdown>
					<div className='search_cont'>
						<Space>
							<Select
								placeholder='Unesite termin za pretragu...'
								className={'search'}
								showSearch
								onSearch={handleOnSearch}
								filterOption={false}
								onChange={handleChangeAutocomplete}
								value={autocompleteSearchValue}
							>
								{searchedCases?.map((item, index) =>{
									return (
										<Option
											key={index}
											value={item.id}
										>
											{item.br_predmeta+', '+item.opis} <span style={{float:'right'}}><small>{item.first_name+' '+item.last_name+' '+item.company_name}</small></span>
										</Option>
									)
								})}
							</Select>
							<Button
								type="primary"
								icon={<FilterOutlined />}
								onClick={() => {setModalFilterVisible(true)}}
								className={'filter'}
							>
								Filter
							</Button>
						</Space>
					</div>
				</BreadcrumbContainer>
				<div className="site-layout-content">
					<DragDropContext onDragEnd={onDragEnd}>
						<Container>
							{
								tabla.columnOrder.map((columnId) => {
									const column = tabla.columns[columnId];
									const predmeti = column.taskIds.map(taskId => tabla.tasks[taskId]);

									return <Column
										key={column.id}
										column={column}
										tasks={predmeti}
										setDisplayModal={setDisplayModal}
										handleClickOnTask = {handleClickOnTask}
										reloadParent={reload}
										setReloadParent={setReload}
									/>
								})
							}
						</Container>

					</DragDropContext>

					<Modal
						title="Kreiranje novog predmeta"
						open={modalNewCaseVisible}
						confirmLoading={confirmLoading}
						onCancel={() => {setModalNewCaseVisible(false)}}
						width={1000}
						destroyOnClose={true}
						okText={'Kreiraj'}
						cancelText={'Otkaži'}
						footer={[
							<Button key="cancel" onClick={() => {setModalNewCaseVisible(false)}}>
								Otkaži
							</Button>,
							<Button type="primary" form="newTaskForm" key="submit" htmlType="submit">
								Kreiraj
							</Button>
						]}
					>
						<NewTaskForm columnId={columnIdForNewTask} onFinish={handleNewTaskOk}/>
					</Modal>

					<ModalCaseDetails
						reloadPredmet={reloadPredmet}
						setReloadPredmet={setReloadPredmet}
						reloadDashboard={reload}
						setReloadDashboard={setReload}
						predmet={predmet}
						setPredmet={setPredmet}
						visible={taskViewModalVisible}
						setTaskViewModalVisible={setTaskViewModalVisible}
					/>

					<ModalFilter
						visible={modalFilterVisible}
						setVisible={setModalFilterVisible}
						reloadParent={reload}
						setReloadParent={setReload}
					/>
				</div>
			</Content>
		</DefaultLayout>
	)
}

export default Dashboard;