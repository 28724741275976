import React, {useState} from "react";
import useUsers from "../Hooks/Users/useUsers";
import axios from "axios";
import {Button, Col, Form, Input, Modal, Row, Select} from "antd";
import useSearchInvestors from "../Hooks/Investors/useSearchInvestors";
import styled from "styled-components";
import {PlusOutlined} from "@ant-design/icons";
import InvestorNewForm from "./InvestorNewForm";
import useIsAdmin from "../Hooks/Users/useIsAdmin";
import useGetUserId from "../Hooks/Users/useGetUserId";

const { Option } = Select;
const { TextArea } = Input;

type IProps = {
	columnId: string
	onFinish: any
}

const Investitor = styled.div`
	display:flex;
	width: 100%;
	justify-content: space-between;
`

const NewTaskForm: React.FC<IProps> = (props:IProps) => {
	const [form] = Form.useForm()
	const {users} = useUsers(1, false)
	const [autocompleteSearchValue, setAutocompleteSearchValue] = useState<string>('')
	const [modalNewInvestorVisible, setModalNewInvestorVisible] = useState<boolean>(false)
	const {investors, setReload} = useSearchInvestors(autocompleteSearchValue)
	const {isAdmin} = useIsAdmin()
	const {userId} = useGetUserId()

	const onFinish = async (formValues:any) => {
		formValues.columnId = props.columnId
		if (!isAdmin) {
			formValues.mentor_multiplier = process.env.REACT_APP_MENTOR_MULTIPLIER ?? 0.2
		}

		try {
			await axios.post('/cases', formValues)
			Modal.success({
				content: 'Predmet je uspešno kreiran',
				onOk() {
					props.onFinish()
				},
			});
		} catch(error) {
			console.log(error)
			Modal.error({
				content: 'Greška prilikom snimanja predmeta u bazu: '+error,
			});
		}
	};

	const handleOnSearch = (value:string) => {
		setAutocompleteSearchValue(value)
	}

	const brPredmetaExist = (value:string) => {
		return axios.get(`/cases/br_predmeta_exist?br_predmeta=${value}`)
			.then(result => {
				if (result.status === 200) {
					return Promise.reject(new Error('Ovaj broj predmeta već postoji u bazi!'));
				}
				if (result.status === 204) {
					return Promise.resolve();
				}
			})
	}

	const onInvestorFinishHandler = () => {
		setModalNewInvestorVisible(false)
		setReload()
	}

	const renderSelectNosilacPredmetaOptions = () => {
		return users.map((user, index) => {
			let option = <></>
			if (isAdmin) {
				option = <Option key={index} value={user.id}>{user.first_name + ' ' + user.last_name}</Option>
			} else {
				if (user.id === parseInt(userId)) {
					option = <Option key={index} value={user.id}>{user.first_name + ' ' + user.last_name}</Option>
				}
			}
			return option;
		})
	}

	return (
		<>
			<Row>
				<Col lg={{span: 18, offset: 1}} md={{span: 16, offset: 4}} xs={{span: 24, offset: 0}} >
					<Form
						id={'newTaskForm'}
						labelCol={{xs: {span: 24}, sm: {span: 12}}}
						wrapperCol={{xs: {span: 24}, sm: {span: 16}}}
						form={form}
						name="create"
						onFinish={onFinish}
						scrollToFirstError
						initialValues={{ nosilac_predmeta_id: isAdmin ? undefined : parseInt(userId) }}
					>
						<Form.Item
							name="br_predmeta"
							label="Broj predmeta"
							rules={[
								{
									type: 'string',
									message: 'Unos moze biti kombinacija slova i brojeva',
								},
								{
									required: true,
									message: 'Unesite broj predmeta',
								},
								() => ({
									validator(_, value) {
										return brPredmetaExist(value)
									},
								}),
							]}
						>
							<Input placeholder="npr. 11/2021" />
						</Form.Item>

						<Form.Item
							name="naziv"
							label="Naziv predmeta"
							rules={[
								{
									type: 'string',
									message: 'Unos moze biti kombinacija slova i brojeva',
								},
								{
									required: true,
									message: 'Unesite naziv predmeta',
								},
							]}
						>
							<Input placeholder="npr. Proširenje magacina" />
						</Form.Item>

						<Form.Item
							name="br_kat_parcele"
							label="Broj katastarske parcele"
							rules={[
								{
									type: 'string',
									message: 'Unos moze biti kombinacija slova i brojeva',
								},
								{
									required: true,
									message: 'Unesite broj katastarske parcele',
								},
							]}
						>
							<Input placeholder="npr. 1234/13" />
						</Form.Item>

						<Form.Item
							name="br_kat_parcele_stari"
							label="Stari broj katastarske parcele"
							rules={[
								{
									type: 'string',
									message: 'Unos moze biti kombinacija slova i brojeva',
								},
							]}
						>
							<Input placeholder="npr. 1522/01" />
						</Form.Item>

						<Form.Item
							name="kat_opstina"
							label="Katastarska opština"
							rules={[
								{
									type: 'string',
									message: 'Unos moze biti kombinacija slova',
								},
								{
									required: true,
									message: 'Unesite katastarsku opštinu',
								},
							]}
						>
							<Input
								style={{
									width: '100%',
								}}
								placeholder="npr. Šabac"
							/>
						</Form.Item>

						<Form.Item
							name="opstina"
							label="Administrativna opština"
							rules={[
								{
									type: 'string',
									message: 'Unos moze biti kombinacija slova',
								},
								{
									required: true,
									message: 'Unesite administrativnu opštinu',
								},
							]}
							hasFeedback
						>
							<Input placeholder="npr. Šabac" />
						</Form.Item>

						<Form.Item
							name="adresa"
							label="Adresa"
							rules={[
								{
									type: 'string',
									message: 'Unos moze biti kombinacija slova i brojeva',
								},
							]}
							hasFeedback
						>
							<Input placeholder="npr. Ulica 11" />
						</Form.Item>

						<Form.Item
							name="napomena"
							label="Napomena"
							rules={[
								{
									type: 'string',
									message: 'Unos moze biti kombinacija slova i brojeva',
								},
							]}
							hasFeedback
						>
							<TextArea rows={4} />
						</Form.Item>

						<Form.Item
							name="opis"
							label="Opis"
							rules={[
								{
									type: 'string',
									message: 'Unos moze biti kombinacija slova i brojeva',
								},
								{
									required: true,
									message: 'Unesite opis predmeta',
								},
							]}
							hasFeedback
						>
							<Input placeholder="npr. Legalizacija kuće" />
						</Form.Item>

						{
							isAdmin ?
								<Form.Item
									name="obrazlozenje"
									label="Finansije"
									rules={[
										{
											type: 'string',
											message: 'Unos moze biti kombinacija slova i brojeva',
										},
									]}
									hasFeedback
								>
									<TextArea rows={4} />
								</Form.Item>
								: ''
						}


						<Form.Item
							name="geometar"
							label="Geometar"
							rules={[
								{
									type: 'string',
									message: 'Unos moze biti kombinacija slova i brojeva',
								},
							]}
							hasFeedback
						>
							<Input placeholder="npr.Petar Petrović" />
						</Form.Item>

						{
							isAdmin ?
								<Form.Item
									name="ugovorena_cena"
									label="Ugovorena cena"
									rules={[
										{
											pattern: new RegExp(/^[0-9]+$/),
											message: 'Unos moze biti samo ceo broj',
										},
									]}
									hasFeedback
								>
									<Input placeholder="npr. 150000" />
								</Form.Item>
								: ''
						}

						<Form.Item
							name="nosilac_predmeta_id"
							label="Nosilac predmeta"
							rules={[
								{
									required: true,
									message: 'Unesite nosioca premeta',
								},
							]}
						>
							<Select
								placeholder="Izaberite nosioca predmeta..."
							>
								{renderSelectNosilacPredmetaOptions()}
							</Select>
						</Form.Item>

						<Form.Item
							name="mentor_id"
							label="Mentor"
							rules={[
								{
									required: false,
									message: 'Izaberite mentora premeta',
								},
							]}
						>
							<Select placeholder="Izaberite mentora predmeta...">
								{
									users.map((user, index) => {
										return (
											<Option key={index} value={user.id}>{user.first_name + ' ' + user.last_name}</Option>
										)
									})
								}
							</Select>
						</Form.Item>

						{
							isAdmin
								? <Form.Item
										name="mentor_multiplier"
										label="Koeficijent za mentora"
										rules={[
											{
												pattern: new RegExp(/^[0-9\\.]+$/),
												message: 'Unos moze biti samo broj',
											},
										]}
										hasFeedback
									>
										<Input placeholder="npr. 0.2" />
									</Form.Item>
								: <Form.Item
										name="mentor_multiplier"
										noStyle
									>
										<Input type="hidden" />
									</Form.Item>
						}

						<Form.Item label="Investitor">
							<Investitor>
								<Form.Item style={{width:"100%"}}
									name="investor_id"
									rules={[
										{
											required: true,
											message: 'Unesite investitora',
										},
									]}
								>
									<Select style={{width:"100%"}}
										placeholder='Izaberite investitora...'
										showSearch
										onSearch={handleOnSearch}
										filterOption={false}
										onChange={handleOnSearch}
										value={autocompleteSearchValue}
									>
										{
											investors.map((investor, index) => {
												return (
													<Option key={index} value={investor.id}>{investor.first_name + ' ' + investor.last_name + ' ' + investor.company_name}</Option>
												)})
										}
									</Select>
								</Form.Item>
								<Button type="primary" onClick={() => setModalNewInvestorVisible(true)}><PlusOutlined /></Button>
							</Investitor>
						</Form.Item>

					</Form>
				</Col>
			</Row>

			<Modal
				title="Kreiranje novog investitora"
				open={modalNewInvestorVisible}
				onCancel={() => setModalNewInvestorVisible(false)}
				destroyOnClose={true}
				width={1000}
				footer={[]}>
				<InvestorNewForm  onFinishHandler={onInvestorFinishHandler} />
			</Modal>
		</>
	)
}

export default NewTaskForm