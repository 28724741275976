import {useEffect, useState} from "react";
import IInvestor from "../../Interfaces/IInvestor";
import axios from "axios";
import {Modal} from "antd";

const useGetInvestor = (investorId: number) => {
	const [investor, setInvestor] = useState<IInvestor>()

	useEffect(() => {
		axios.get(`/investors/${investorId}`)
			.then((response) => {
				setInvestor(response.data.data)
			})
			.catch((error) => {
				console.log(error)
				Modal.error({
					content: 'Greška: '+error,
				});
			})
	}, [investorId])

	return {
		investor,
		setInvestor,
	}
}

export default useGetInvestor