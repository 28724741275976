import React from "react";
import { Table } from 'antd';
import useGetGenericIzvestaj from "../../Hooks/Reports/useGetGenericIzvestaj";

const Sumarni: React.FC = () => {

	const {report} = useGetGenericIzvestaj('PredmetiPoStatusima')

	const columns = [
		{
			title: 'Status predmeta',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Broj predmeta',
			dataIndex: 'total',
			key: 'total',
		},
	];


	return (
		<Table columns={columns} dataSource={report} pagination={false}/>
	)
}

export default Sumarni