import {useState} from "react";

const useGetUserId = () => {
	let userIdString = localStorage.getItem('user_id')
	if (userIdString === null) {
		userIdString = ''
	}
	const [userId] = useState<string>(userIdString)

	const setUserId = (value:string) => {
		if (value !== '') {
			localStorage.setItem('user_id', value)
		} else {
			localStorage.removeItem('user_id')
		}
	}

	return {
		userId,
		setUserId
	}
}

export default useGetUserId