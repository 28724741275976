import {useEffect, useState} from "react";
import axios from "axios";
import {Modal} from "antd";

type IReportRow = {
	month: string
	total: number
	name?: string,
	user_id?: string
}

const useGetReportGodisnjiPoMesecima = (userId: string|undefined = undefined) => {
	const [report, setReport] = useState<IReportRow[]>([])

	let url = `/izvestaj/godisnji`
	if (userId !== null && userId !== undefined) {
		url += '?user_id='+userId
	}

	useEffect(() => {
		axios.get(url)
			.then((response) => {
				setReport(response.data)
			})
			.catch((error) => {
				console.log(error)
				Modal.error({
					content: 'Greška: '+error,
				});
			})
	}, [url])

	return {
		report
	}
}

export default useGetReportGodisnjiPoMesecima