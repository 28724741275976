import {useEffect, useState} from "react";
import IUser from "../../Interfaces/IUser";
import axios from "axios";
import {Modal} from "antd";

const useGetUser = (userId: number) => {
	const [user, setUser] = useState<IUser>()

	useEffect(() => {
		axios.get(`/users/${userId}`)
			.then((response) => {
				setUser(response.data.data)
			})
			.catch((error) => {
				console.log(error)
				Modal.error({
					content: 'Greška: '+error,
				});
			})
	}, [userId])

	return {
		user,
		setUser,
	}



}

export default useGetUser