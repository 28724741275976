import React, {useState} from "react";
import {Breadcrumb, Card, Col, Row} from "antd";
import {Content} from "antd/lib/layout/layout";
import DefaultLayout from "../Components/DefaultLayout";
import useGetDashboardsForDropDown from "../Hooks/Dashboards/useGetDashboardsForDropDown";
import {useHistory} from "react-router-dom";

const DashboardSelect:React.FC = () => {

	const [reload] = useState(false)
	const {dashboards} = useGetDashboardsForDropDown(reload)
	const history = useHistory();

	const onTablaSelect = (id: number) => {
		localStorage.setItem('tablaId', id.toString())
		history.push('/')
	}
	return (
		<DefaultLayout>
			<Content style={{ padding: '0 50px' }}>
				<Breadcrumb style={{ margin: '16px 0' }}>
					<Breadcrumb.Item>Početna</Breadcrumb.Item>
					<Breadcrumb.Item>Izbor table</Breadcrumb.Item>
				</Breadcrumb>
				<div className="site-card-wrapper">
					<Row gutter={16} justify="center">
						{
							dashboards?.map((dashboard, index) => {
								return (
									<Col key={index} span={8}>
										<Card title={dashboard.title} bordered={false} hoverable onClick={() => onTablaSelect(dashboard.id)}>
											{dashboard.description}
										</Card>
									</Col>
								)
							})
						}
					</Row>
				</div>
			</Content>
		</DefaultLayout>
	)
}

export default DashboardSelect