import React, {useState} from "react";
import {Button, Timeline, Tooltip} from "antd";
import ITimelinePoint from "../Interfaces/ITimelinePoint";
import {EditOutlined} from "@ant-design/icons";
import ModalTimelineComment from "./ModalTimelineComment";
import ModalTimelineCommentsHistory from "./ModalTimelineCommentsHistory";

type IProps = {
	timeline: ITimelinePoint[]|undefined
	className: string|undefined
	caseId?: string,
	reloadPredmet: boolean,
	setReloadPredmet: any,
}

const CaseTimeline: React.FC<IProps> = (props:IProps) => {

	const [modalCommentAction, setModalCommentAction] = useState<'save'|'update'>('save')
	const [modalCommentVisible, setModalCommentVisible] = useState(false)
	const [timelineComment, setTimelineComment] = useState<string>('')
	const [timelineCommentId, setTimelineCommentId] = useState<number|undefined>()
	const [modalCommentsHistoryVisible, setModalCommentsHistoryVisible] = useState(false)
	const [showCommentId, setShowCommentId] = useState<number>()
	const [reloadCommentsHistory, setReloadCommentsHistory] = useState(false)

	const displayEditIcon = (timelinePoint: ITimelinePoint) => {
		if (timelinePoint.action === 'comment') {
			return (
				<Tooltip title="Izmeni komentar">
					<EditOutlined
						className="clickable"
						onClick={() => {
							setTimelineCommentId(timelinePoint.id)
							setModalCommentAction('update')
							setTimelineComment(timelinePoint.comment)
							setModalCommentVisible(true)
						}}
					/>
				</Tooltip>
			)
		}
		return ''
	}

	const timelineItems = () => {
		if (props.timeline === undefined) return

		return props.timeline.map((timelineItem, index) => {
			let color = 'green'
			if (timelineItem.type === 'success') {
				color = 'green'
			}
			else if (timelineItem.type === 'warning') {
				color = 'orange'
			}
			else if (timelineItem.type === 'info') {
				color = 'blue'
			}
			else if (timelineItem.type === 'error') {
				color = 'red'
			}
			else if (timelineItem.type === 'disabled') {
				color = 'grey'
			}

			let comment
			if (timelineItem.comment !== null && timelineItem.comment !== '') {
				comment = <><small className="nl2br">{timelineItem.comment}</small><br /></>
			} else {
				comment = ''
			}

			const edited = (commentId: number) => {
				if (timelineItem.edited) {
					return (
						<Tooltip title="Pogledaj istoriju izmena">
							<span
								className="clickable"
								onClick={ () => {
									setShowCommentId(commentId)
									setModalCommentsHistoryVisible(true)
								}}
							>
								Izmenjeno
							</span>
						</Tooltip>
					)
				}
			}

			return (
				<Timeline.Item key={index} color={color}>
					{timelineItem.title} {displayEditIcon(timelineItem)}<br />
					{comment}
					<small style={{fontStyle: "italic"}}>
						{edited(timelineItem.id)}&nbsp;
						{new Intl.DateTimeFormat('sr-RS').format(new Date(timelineItem.created_at))}&nbsp;
						{timelineItem.user.first_name} {timelineItem.user.last_name}
					</small>
				</Timeline.Item>
			)
		})
	}

	return (
		<>
			<Timeline className={props.className}>
				<Button
					style={{float: "right"}}
					type="default"
					onClick={() => {
						setModalCommentAction('save')
						setModalCommentVisible(true)
					}}
				>
						Dodaj komentar
				</Button>
				<br /><br />
				{timelineItems()}
			</Timeline>

			<ModalTimelineComment
				visible={modalCommentVisible}
				setVisible={setModalCommentVisible}
				caseId={props.caseId}
				reloadParent={props.reloadPredmet}
				setReloadParent={props.setReloadPredmet}
				reloadCommentsHistory={reloadCommentsHistory}
				setReloadCommentsHistory={setReloadCommentsHistory}
				action={modalCommentAction}
				timelineComment={timelineComment}
				setTimelineComment={setTimelineComment}
				timelineCommentId={timelineCommentId}
			/>

			<ModalTimelineCommentsHistory
				commentId={showCommentId}
				visible={modalCommentsHistoryVisible}
				setVisible={setModalCommentsHistoryVisible}
				reloadCommentsHistory={reloadCommentsHistory}
			/>
		</>
	)
}

export default CaseTimeline;