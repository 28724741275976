import React from "react";
import {Content} from "antd/lib/layout/layout";
import {Breadcrumb, Row, Col, Card, Avatar, Space, Typography, Tabs} from "antd";
import DefaultLayout from "../../Components/DefaultLayout";
import {UserOutlined} from "@ant-design/icons";
import useGetUser from "../../Hooks/Users/useGetUser";
import useGetUserId from "../../Hooks/Users/useGetUserId";
import PointsTab from "./PointsTab";
import IzvestajTab from "./IzvestajTab";
const {Title} = Typography

const ProfilePage: React.FC = () => {
	const {userId} = useGetUserId()
	const {user} = useGetUser(parseInt(userId))
	const items = [
		{ label: 'Bodovi', key: 'bodovi', children: <PointsTab/> },
		{ label: 'Izveštaj', key: 'izvestaj', children: <IzvestajTab/> },
	];

	return (
		<DefaultLayout>
			<Content style={{ padding: '0 50px' }}>
				<Breadcrumb style={{ margin: '16px 0' }}>
					<Breadcrumb.Item>Početna</Breadcrumb.Item>
					<Breadcrumb.Item>Profil korisnika</Breadcrumb.Item>
				</Breadcrumb>

				<Row gutter={16}>
					<Col span={6}>
						<div className="site-card-border-less-wrapper">
							<Card bordered={false} style={{textAlign: 'center'}}>
								<Space align='center' direction='vertical'>
									<Avatar size={128} icon={<UserOutlined />} />
									<Title level={4}>{user?.first_name+' '+user?.last_name}</Title>
									<p>{user?.email}</p>
									<p>{user?.phone}</p>
								</Space>
							</Card>
						</div>
					</Col>
					<Col span={18}>
						<div className="site-card-border-less-wrapper">
							<Card bordered={false}>
								<Tabs items={items} />
							</Card>
						</div>

					</Col>
				</Row>

			</Content>
		</DefaultLayout>
		)
}

export default ProfilePage