import React, {useState} from "react";
import {Content} from "antd/lib/layout/layout";
import {Breadcrumb, Button, Input, Modal, Row, Select, Space, Table} from "antd";
import Title from "antd/es/typography/Title";
import DefaultLayout from "../Components/DefaultLayout";
import useGetUserPoints from "../Hooks/UserPoints/useGetUserPoints";
import IUser from "../Interfaces/IUser";
import ITablaColumn from "../Interfaces/ITablaColumn";
import ICase from "../Interfaces/ICase";
import { AlignType } from 'rc-table/lib/interface';
import axios from "axios";
import useGetUserId from "../Hooks/Users/useGetUserId";
import useIsAdmin from "../Hooks/Users/useIsAdmin";
import ModalCaseDetails from "../Components/ModalCaseDetails";
import useGetCase from "../Hooks/Cases/useGetCase";
import {ColumnsType} from "antd/es/table";
import useUsers from "../Hooks/Users/useUsers";
const {Option} = Select;

type IRecord = {
	key: number,
	id: number,
	user?: IUser,
	description: string,
	predmet?: ICase,
	start_column: ITablaColumn,
	end_column: ITablaColumn,
	mentor?: IUser,
	points: number,
	approver?: IUser,
	approved: number,
}

const BodoviPage: React.FC = () => {
	const [page, setPage] = useState(1)
	const [reload, setReload] = useState(false)
	const {users} = useUsers(1, false)
	const [selectedUser, setSelectedUser] = useState<null|string>(null)
	const {points, pagination} = useGetUserPoints(page, 20, reload, selectedUser)
	const [modalOdobriVisible, setModalOdobri] = useState(false)
	const [modalDetailsVisible, setModalDetailsVisible] = useState(false)
	const [userPoints, setUserPoints] = useState<number>(0)
	const [selectedRecord, setSelectedRecord] = useState<IRecord>()
	const [selectedRecords, setSelectedRecords] = useState<IRecord[]>([])
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const {userId} = useGetUserId()
	const {isAdmin} = useIsAdmin()
	const [selectedPredmet, setSelectedPredmet] = useState('')
	const [reloadPredmet, setReloadPredmet] = useState(false)
	const {predmet, setPredmet} = useGetCase(selectedPredmet, reloadPredmet)


	const columns: ColumnsType<IRecord> = [
		{
			title: 'Bodovi za',
			dataIndex: 'user',
			key: 'user',
			render: (user: IUser) => user.first_name + ' ' + user.last_name,
		},
		{
			title: 'Opis',
			dataIndex: 'description',
			key: 'description',
		},
		{
			title: 'Predmet',
			dataIndex: 'predmet',
			key: 'predmet',
			render: (predmet: ICase) => {
				return predmet ? <><Button type='link' onClick={() => {
					setSelectedPredmet(predmet.id)
					setModalDetailsVisible(true)
				}
				}>{predmet.naziv}</Button><br /><small>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{predmet.opis}</small> </>: ''
			},
		},
		{
			title: 'Iz kolone',
			dataIndex: 'start_column',
			key: 'start_column',
			render: (column: ITablaColumn) => column.title,
		},
		{
			title: 'U kolonu',
			dataIndex: 'end_column',
			key: 'start_column',
			render: (column: ITablaColumn) => column.title,
		},
		{
			title: 'Bodova',
			dataIndex: 'points',
			key: 'points',
			align: 'center' as AlignType,
		},
		{
			title: 'Odobrio',
			dataIndex: 'approver',
			key: 'approver',
			render: (user: IUser) => user !== null ? user?.first_name + ' ' + user?.last_name : '',
		},
		{
			title: 'Odobreno',
			dataIndex: 'approved',
			key: 'approved',
			hidden: true
		},
		{
			title: '',
			key: 'action',
			render: (record:any) => actionButtons(record),
		},
	].filter(item => !item.hidden);

	const actionButtons = (record:any) => {
		let odobriButton = () => {
			if (record.approved !== 1) {
				return (
					<Button type="primary" size={'middle'} onClick={() => {
						setUserPoints(record.points)
						setSelectedRecord(record)
						setModalOdobri(true)
					}}>Odobri</Button>
					)
			}
		}

		if (isAdmin) {
			return (
				<Space size="middle">
					{odobriButton()}
				</Space>
			)
		}
	}

	const handleOdobri = () => {
		setModalOdobri(false)
		let payload = {
			approved: 1,
			points: userPoints,
			approver_id: userId
		}
		axios.patch(`/user-points/${selectedRecord?.id}`, payload)
			.then(() => {
				setReload(!reload)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const handleTableChange = (pagination: any) => {
		setPage(pagination.current)
	}

	const handleOdobriGrupno = () => {
		let payload = selectedRecords.map((record: IRecord) => {
			return {
				id: record.id,
				approved: 1,
				points: record.points,
				approver_id: userId
			}
		})
		axios.patch(`/user-points`, payload)
			.then(() => {
				setSelectedRowKeys([]);
				setSelectedRecords([]);
				setReload(!reload)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const renderOdobriGrupnoButton = () => {
		let disabledButton = !(selectedRecords.length > 0)
		return (
			<Button
				type='primary'
				onClick={handleOdobriGrupno}
				disabled={disabledButton}
			>
				Odobri grupno
			</Button>
		)
	}

	return (
		<DefaultLayout>
			<Content style={{ padding: '0 50px' }}>
				<Breadcrumb style={{ margin: '16px 0' }}>
					<Breadcrumb.Item>Početna</Breadcrumb.Item>
					<Breadcrumb.Item>Bodovi</Breadcrumb.Item>
				</Breadcrumb>
				<Row>
					<Title level={3}>Bodovi</Title>
				</Row>

				<div className='search_investors'>
					{renderOdobriGrupnoButton()}
					<Select
						placeholder='Izaberite korisnika za filtriranje po korisniku'
						className={'search'}
						showSearch
						// onSearch={(value:string) => {setAutocompleteSearchValue(value)}}
						filterOption={false}
						onChange={(value: string) => setSelectedUser(value)}
						// value={autocompleteSearchValue}
					>
						{users?.map((item: IUser, index: number) =>{
							return (
								<Option
									key={index}
									value={item.id}
								>
									{item.first_name+' '+item.last_name+' '}
								</Option>
							)
						})}
					</Select>
				</div>
				<br /><br />
				<Table columns={columns}
          dataSource={points?.map((point, index) => (
		       {
			       key:index,
			       id:point.id,
			       user: point.user,
			       description: point.description,
			       predmet: point.case,
			       start_column: point.start_column,
			       end_column: point.end_column,
			       mentor: point.mentor,
			       points: point.points,
			       approver: point.approver,
			       approved: point.approved,
		       }))}
	       pagination={pagination}
         rowClassName={record => record.approved === 1 ? 'approved' : ''}
	       onChange={handleTableChange}
	       rowSelection={{
		      selectedRowKeys,
	       	type: 'checkbox',
	        onChange: (newSelectedRowKeys: React.Key[], selectedRows: IRecord[]) => {
	       		// console.log(`selectedRowKeys: ${newSelectedRowKeys}`, 'selectedRows: ', selectedRows);
	       		setSelectedRecords(selectedRows)
		        setSelectedRowKeys(newSelectedRowKeys)
	        },
	        getCheckboxProps: (record: any) => ({
		        disabled: record.approved === 1, // Column configuration not to be checked
			      name: record.id,
	        }),
	       }}
				/>
				<Modal
					title="Odobri"
					open={modalOdobriVisible}
					onCancel={() => {setModalOdobri(false)}}
					onOk={handleOdobri}
					destroyOnClose={true}
					okText={'Odobri'}
					cancelText={'Otkaži'}
				>
					<Input
						type="number"
						placeholder="Basic usage"
						defaultValue={userPoints}
						value={userPoints}
						onChange={(e) => {setUserPoints(parseInt(e.target.value))}}/>
				</Modal>
				<ModalCaseDetails
					reloadPredmet={reloadPredmet}
					setReloadPredmet={setReloadPredmet}
					reloadDashboard={false}
					setReloadDashboard={() => {
					}}
					predmet={predmet}
					setPredmet={setPredmet}
					visible={modalDetailsVisible}
					setTaskViewModalVisible={setModalDetailsVisible}
				/>
			</Content>
		</DefaultLayout>
		)
}

export default BodoviPage