import React, {useState} from "react";
import {Button, InputNumber, Modal, Timeline} from "antd";
import IPayment from "../Interfaces/IPayment";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";

type IProps = {
	payments: IPayment[]|undefined
	caseId?: string,
	ugovorena_cena: number,
	reloadPredmet: boolean,
	setReloadPredmet: any,
}

const PaymentsTimeline: React.FC<IProps> = ({payments, caseId, ugovorena_cena, reloadPredmet, setReloadPredmet}) => {

	if (payments === undefined) payments = []

	const [modalUplataVisible, setModalUplataVisible] = useState(false)
	const [uplataIznos, setUplataIznos] = useState<number>()
	const [uplataComment, setUplataComment] = useState('')

	const saveNovaUplata = () => {
		if (uplataIznos===0 || uplataIznos === undefined) {
			Modal.error({
				content: 'Iznos uplate je obavezan!',
				onOk() {
					return
				},
			})
		}

		axios.post(`/payments`, {caseId:caseId, comment:uplataComment, value: uplataIznos})
			.then(() => {
				setReloadPredmet(!reloadPredmet)
				Modal.success({
					content: 'Uspešeno ste snimili uplatu',
					onOk() {
						setModalUplataVisible(false)
					},
				});
			})
			.catch((error) => {console.log(error)})
	}

	const paid = () => {
		let paidAmount = 0;
		payments?.forEach((payment) => {
			paidAmount += payment.value
		})
		return paidAmount
	}

	const total = () => {
		return ugovorena_cena - paid()
	}

	return (
		<>
			<Button style={{float: "right"}} type="primary" onClick={() => {setModalUplataVisible(true)}}>Nova uplata</Button><br /><br />
			<Timeline>
				{
					payments.map((payment, index) => {
						let comment
						if (payment.comment !== null) {
							comment = <><small>{payment.comment}</small><br /></>
						} else {
							comment = ''
						}
						return (
							<Timeline.Item key={index} color={'green'}>
								{payment.value} EUR<br />
								{comment}
								<small style={{fontStyle: "italic"}}>{new Intl.DateTimeFormat('sr-RS').format(new Date(payment.created_at))} {payment.user.first_name} {payment.user.last_name}</small>
								{/*<small>{timelineItem.comment}</small><br />*/}
								{/*<small>{new Intl.DateTimeFormat('sr-RS').format(new Date(timelineItem.created_at))} {timelineItem.user.first_name} {timelineItem.user.last_name}</small>*/}
							</Timeline.Item>
						)
					})
				}
			</Timeline>

			<table style={{width: "100%", fontWeight: "bold"}}>
				<tbody>
					<tr>
						<td align="right">Vrednost projekta:</td>
						<td align="right">{ugovorena_cena} EUR</td>
					</tr>
					<tr>
						<td align="right">Uplaćeno:</td>
						<td align="right">{paid()} EUR</td>
					</tr>
					<tr>
						<td align="right">Ostalo za naplatu:</td>
						<td align="right">{total()} EUR</td>
					</tr>
				</tbody>
			</table>

			<Modal
				title="Nova uplata"
				open={modalUplataVisible}
				onCancel={() => setModalUplataVisible(false)}
				destroyOnClose={true}
				footer={[
					<Button type="primary" key={0} onClick={() => {saveNovaUplata()}}>
						Snimi
					</Button>,
				]}>
				<InputNumber
					placeholder='Unesite iznos...'
					value={uplataIznos}
					onChange={setUplataIznos}
					style={{width: '200px'}}
				/>
				<br /><br />
				<TextArea
					placeholder="Unesite komentar..."
					rows={4}
					value={uplataComment}
					onChange={(e) => setUplataComment(e.target.value)}
				/>
			</Modal>
		</>
	)
}

export default PaymentsTimeline;