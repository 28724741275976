import {useEffect, useState} from "react";
import axios from "axios";
import {Modal} from "antd";
import ICaseAutocomplete from "../../Interfaces/ICaseAutocomplete";

const useSearchCases = (searchTerm: string) => {
	const [searchedCases, setSearchedCases] = useState<ICaseAutocomplete[]>()

	searchTerm = searchTerm ?? ''

	useEffect(() => {
		axios.get(`/cases/search?search=${searchTerm}`)
			.then((response) => {
				setSearchedCases(response.data.data)
			})
			.catch((error) => {
				console.log(error)
				Modal.error({
					content: 'Greška: '+error,
				});
			})
	}, [searchTerm])

	return {
		searchedCases,
		setSearchedCases,
	}
}

export default useSearchCases