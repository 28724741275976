import React from "react"
import {LockOutlined} from "@ant-design/icons"

const Forbidden: React.FC = () => {
	return (
		<>
			<br /><br />
			<h1 style={{textAlign: 'center'}}>Pristup zabranjen</h1>
			<p style={{textAlign: 'center'}}>
				Nemate potrebne dozvole za pristup ovoj stranici!
			</p>
			<p style={{textAlign: 'center', fontSize: '42px'}}>
				<LockOutlined />
			</p>
		</>

	)
}

export default Forbidden