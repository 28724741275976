import {useEffect, useState} from "react";
import axios from "axios";
import {Modal} from "antd";

const useGetNotificationsCount = (reload: boolean,	userId: string|undefined) => {

	const [notifications, setNotifications] = useState(0)

	useEffect(() => {
		axios.get(`/notifications/count?userId=${userId}`)
			.then((response) => {
				setNotifications(response.data.total)
			})
			.catch((error) => {
				console.log(error)
				Modal.error({
					content: 'Greška: '+error,
				});
			})
	}, [userId, reload])

	return {
		notifications,
	}
}

export default useGetNotificationsCount