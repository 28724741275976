import React from "react";
import {Button, Modal, Timeline} from "antd";
import useGetCommentsHistory from "../Hooks/Timeline/useGetComments";

type IProps = {
	commentId?: number
	visible: boolean
	setVisible: any
	reloadCommentsHistory: any
}

const ModalTimelineCommentsHistory: React.FC<IProps> = ({reloadCommentsHistory, commentId, visible, setVisible}) => {

	const [comments] = useGetCommentsHistory(commentId, reloadCommentsHistory)

	const renderTimelineItems = () => {

		return comments.map((timelineItem, index) => {
			return (
				<Timeline.Item key={index} color='green'>
					<span className="nl2br">{timelineItem.comment}</span><br/>
					<small style={{fontStyle: "italic"}}>
						{new Intl.DateTimeFormat('sr-RS').format(new Date(timelineItem.created_at))}&nbsp;
						{timelineItem.user.first_name} {timelineItem.user.last_name}
					</small>
				</Timeline.Item>
			)
		})
	}

	return(
		<Modal
			title="Istorija izmene komentara"
			open={visible}
			onCancel={() => setVisible(false)}
			destroyOnClose={true}
			footer={[
				<Button type="primary" key={0} onClick={() => setVisible(false)}>
					OK
				</Button>,
			]}
		>
			{renderTimelineItems()}
		</Modal>
	)
}

export default ModalTimelineCommentsHistory