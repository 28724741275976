import React from "react";
import {Button, Form, Input, Modal} from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";

const formItemLayout = {
	labelCol: {
		xs: {
			span: 24,
		},
		sm: {
			span: 8,
		},
	},
	wrapperCol: {
		xs: {
			span: 24,
		},
		sm: {
			span: 16,
		},
	},
};

const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 16,
			offset: 8,
		},
	},
};

type IProps = {
	onFinishHandler: any
}

const InvestorNewForm: React.FC<IProps> = ({onFinishHandler}) => {

	const [form] = Form.useForm();

	const onFinish = async (formValues:any) => {
		try {
			await axios.post('/investors', formValues)
			Modal.success({
				content: 'Investitor je uspešno kreiran',
				onOk() {
					onFinishHandler()
				},
			});
		} catch(error) {
			console.log(error)
			let message = ''
			if (error.response) {
				message = error.response.data.message
			} else {
				message = error
			}
			Modal.error({
				content: 'Greška prilikom snimanja investitora u bazu: ' + message,
			});
		}
	};

	return (
		<Form
			{...formItemLayout}
			form={form}
			name="create"
			onFinish={onFinish}
			scrollToFirstError
		>
			<Form.Item
				name="first_name"
				label="Ime"
				rules={[
					{
						required: false,
						message: 'Unesite ime',
					},
				]}
			>
				<Input placeholder="npr. Petar" />
			</Form.Item>

			<Form.Item
				name="last_name"
				label="Prezime"
				rules={[
					{
						required: false,
						message: 'Unesite prezime',
					},
				]}
			>
				<Input placeholder="npr. Petrović" />
			</Form.Item>

			<Form.Item
				name="phone"
				label="Telefon investitora"
				rules={[
					{
						required: false,
						message: 'Unesite broj telefona. Samo brojevi su dozvoljeni',
						type: "regexp",
						pattern: new RegExp(/\d+/g),
					},
				]}
			>
				<Input
					style={{
						width: '100%',
					}}
					placeholder="npr. 0641234567"
				/>
			</Form.Item>

			<Form.Item
				name="company_name"
				label="Naziv firme"
				rules={[
					({ getFieldValue }) => ({
						validator(_, value) {
							if (!value && (getFieldValue('first_name') === undefined && getFieldValue('last_name') === undefined)) {
								return Promise.reject(new Error('Unesite naziv firme ukoliko niste uneli ime i prezime'));
							}
							return Promise.resolve();
						},
					}),
				]}
			>
				<Input placeholder="npr. Kompanija doo" />
			</Form.Item>

			<Form.Item
				name="company_phone"
				label="Telefon firme"
				rules={[
					({ getFieldValue }) => ({
						validator(_, value) {
							if (!value && getFieldValue('phone') === undefined ) {
								return Promise.reject(new Error('Unesite telefon firme ukoliko niste uneli telefon investitora'));
							}
							return Promise.resolve();
						},
					}),
				]}
			>
				<Input
					style={{
						width: '100%',
					}}
					placeholder="npr. 0641234567"
				/>
			</Form.Item>

			<Form.Item
				name="address"
				label="Adresa"
				rules={[
					{
						required: true,
						message: 'Unesite adresu',
					},
				]}
			>
				<Input placeholder="npr. Ulica 1" />
			</Form.Item>

			<Form.Item
				name="postal_code"
				label="Poštanski broj"
				rules={[
					{
						required: true,
						message: 'Unesite poštanski broj',
					},
				]}
			>
				<Input placeholder="npr. 15000" />
			</Form.Item>

			<Form.Item
				name="city"
				label="Mesto"
				rules={[
					{
						required: true,
						message: 'Unesite mesto',
					},
				]}
			>
				<Input placeholder="npr. Šabac" />
			</Form.Item>

			<Form.Item
				name="note"
				label="Napomena"
			>
				<TextArea
					placeholder="Dodatne napomene vezane za investitora..."
					rows={5}
				/>
			</Form.Item>

			<Form.Item
				name="contact_person_first_name"
				label="Ime kontakt osobe"
				rules={[
					{
						required: true,
						message: 'Unesite ime',
					},
				]}
			>
				<Input placeholder="npr. Petar" />
			</Form.Item>

			<Form.Item
				name="contact_person_last_name"
				label="Prezime kontakt osobe"
				rules={[
					{
						required: true,
						message: 'Unesite prezime',
					},
				]}
			>
				<Input placeholder="npr. Petrović" />
			</Form.Item>

			<Form.Item
				name="contact_person_phone"
				label="Telefon kontakt osobe"
				rules={[
					{
						required: true,
						message: 'Unesite broj telefona. Samo brojevi su dozvoljeni',
						pattern: new RegExp(/\d+/g),
					},
				]}
			>
				<Input
					style={{
						width: '100%',
					}}
					placeholder="npr. 0641234567"
				/>
			</Form.Item>

			<Form.Item {...tailFormItemLayout}>
				<Button size={"large"} type="primary" htmlType="submit">
					Kreiraj
				</Button>
			</Form.Item>
		</Form>
	)
}

export default InvestorNewForm