import axios from "axios";

export const logout = (setIsAdmin:any, setAuthedHelper:any) => {
	// eslint-disable-next-line no-restricted-globals
	location.href = '/login';
	localStorage.removeItem('user_name')
	localStorage.removeItem('authed')
	localStorage.removeItem('isAdmin')
	setIsAdmin(undefined)
	setAuthedHelper(false)
	axios.post('logout')
		.then((response) => {

		})
		.catch((error) => {
			console.log(error)
		})
}

export const selectedMenuArr = () => {
	let currentPath = window.location.pathname
	let parts = currentPath.split('/')
	let stripped = parts[1]
	if (!stripped || stripped === '') {
		stripped = 'home'
	}
	return [stripped]
}