import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from "axios";
import store from "./Redux/store";
import { Provider } from "react-redux"

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.withCredentials = true;
// Add a 401 response interceptor
axios.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	if (401 === error.response.status) {
		window.location.href = '/login';
	} else {
		return Promise.reject(error);
	}
});

ReactDOM.render(
  // <React.StrictMode>
	<Provider store={store}>
		<App />
	</Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
