import React, {useState} from "react";
import {Content} from "antd/lib/layout/layout";
import {Breadcrumb, Col, Row} from "antd";
import DefaultLayout from "../../Components/DefaultLayout";
import Title from "antd/es/typography/Title";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import InvestorNewForm from "../../Forms/InvestorNewForm";



const FormTitle = styled.div`
	text-align: center;
	margin-bottom: 30px;
`

const InvestorCreate: React.FC = () => {
	const [redirect, setRedirect] = useState(false);

	if (redirect) {
		return(<Redirect to={'/investitori'} />)
	}

	return (
		<DefaultLayout>
			<Content style={{ padding: '0 50px' }}>
				<Breadcrumb style={{ margin: '16px 0' }}>
					<Breadcrumb.Item>Početna</Breadcrumb.Item>
					<Breadcrumb.Item>Korisnici</Breadcrumb.Item>
					<Breadcrumb.Item>Unos novog investitora</Breadcrumb.Item>
				</Breadcrumb>
				<Row>
					<Col lg={{span: 12, offset: 6}} md={{span: 16, offset: 4}} xs={{span: 24, offset: 0}} >

						<FormTitle>
							<Title level={3}>Unos novog investitora</Title>
						</FormTitle>

						<InvestorNewForm onFinishHandler={() => setRedirect(true)} />
					</Col>
				</Row>
			</Content>
		</DefaultLayout>
	)
}

export default InvestorCreate