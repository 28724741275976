import React from "react";
import styled  from "styled-components"
import {Draggable} from "react-beautiful-dnd";
import {Link} from "react-router-dom";
import {
	BankOutlined,
	UserOutlined,
	SmileOutlined,
	StopOutlined,
	EuroOutlined,
	DeleteOutlined,
	CheckOutlined,
	VideoCameraOutlined,
	UserAddOutlined
} from "@ant-design/icons";
import {Tooltip} from "antd";
import useGetUserId from "../Hooks/Users/useGetUserId";
import useIsAdmin from "../Hooks/Users/useIsAdmin";

const Container = styled.div`
	border: 1px solid lightgrey;
	padding: 5px;
	margin-bottom: 5px;
	border-radius: 3px;
	box-shadow: 2px 2px 2px 1px lightgrey;
	background: ${(containerProps:{isDragging: boolean, isDragDisabled: boolean, priority:string, status_id: number}) => (
		containerProps.isDragDisabled 
			? containerProps.priority === 'high'
					? '#ffc7c7'
					: '#f9f9f9'
			: containerProps.isDragging 
				? 'lightGreen' 
				: containerProps.priority === 'high'
					? '#ffc7c7'
					: containerProps.status_id === 6
						? '#e7e7e7d9'
						: 'white'
	)};
`

const Title = styled.h5`
	margin-bottom: 0;
	font-weight: normal;
`

const InvestorTitle = styled.h5`
	margin-bottom: 0;
	font-weight: normal;
	padding-right: 5px;
`

const CardIcon = styled.div`
	float:right;
	margin-top: -20px;
`
const VerifiedIcon = styled.div`
	float:right;
	margin-top: 0;
	color: gray;
`

type props = {
	index: number,
	case_id: string
	case_title: string,
	investor: string,
	task_owner: number,
	status_id: number,
	handleClickOnTask: any,
	priority: string,
	verified: boolean,
}

const Task: React.FC<props> = (props:props) => {
	const {userId} = useGetUserId()
	const {isAdmin} = useIsAdmin()

	const isDragDisabled = (props.task_owner !== parseInt(userId)) && (!isAdmin)

	const cardIcon = () => {
		switch (props.status_id) {
			case 1:
				return (<Tooltip title="U toku..."><SmileOutlined style={{color: '#00802b'}} /></Tooltip>)
			case 2:
				return (<Tooltip title="Čeka se državni organ"><BankOutlined /></Tooltip>)
			case 3:
				return (<Tooltip title="Čeka se investitor"><UserOutlined /></Tooltip>)
			case 4:
				return (<Tooltip title="Čeka se naplata"><EuroOutlined /></Tooltip>)
			case 5:
				return (<Tooltip title="Predmet stopiran"><StopOutlined style={{color: 'red'}}/></Tooltip>)
			case 6:
				return (<Tooltip title="Predmet arhiviran"><DeleteOutlined/></Tooltip>)
			case 7:
				return (<Tooltip title="Čeka se geometar"><VideoCameraOutlined/></Tooltip>)
			case 8:
				return (<Tooltip title="Čeka se treće lice"><UserAddOutlined /></Tooltip>)
			default:
				return ('')
		}
	}

	const verifiedIcon = () => {
			return props.verified ? (<Tooltip title="Bodovi su verifikovani"><CheckOutlined /></Tooltip>) : ''
	}

	return (
		<Draggable
			draggableId={props.case_id}
			index={props.index}
			isDragDisabled={isDragDisabled}
		>
			{(provided, snapshot) => (
				<Container
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					ref={provided.innerRef}
					isDragging={snapshot.isDragging}
					isDragDisabled={isDragDisabled}
					priority={props.priority}
					status_id={props.status_id}
				>
					<VerifiedIcon>{verifiedIcon()}</VerifiedIcon>
					<Title>
						<Link to={''} onClick={() => props.handleClickOnTask(props.case_id)}>
							{props.case_title}
						</Link>

					</Title>
					<InvestorTitle>
						<small>{props.investor}</small>
					</InvestorTitle>
					<CardIcon>{cardIcon()}</CardIcon>
				</Container>
			)}
		</Draggable>

	)
}

export default Task;