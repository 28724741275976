import React, {useState} from "react";
import {Breadcrumb, Table, Tag, Space, Button, Modal, Select} from 'antd';
import {Content} from "antd/lib/layout/layout";
import DefaultLayout from "../../Components/DefaultLayout";
import {Link, useHistory} from "react-router-dom";
import useGetUsers from "../../Hooks/Users/useUsers";
import axios from "axios";
import useIsAdmin from "../../Hooks/Users/useIsAdmin";
const { Option } = Select;

const Users:React.FC = () => {
	const history = useHistory();
	const [page, setPage] = useState(1)
	const [reload, setReload] = useState(false)
	const {users, pagination} = useGetUsers(page, reload)
	const {isAdmin} = useIsAdmin()
	const [modalBrisanjeKorisnikaVisible, setModalBrisanjeKorisnikaVisible] = useState(false)
	const [selectedRecord, setSelectedRecord] = useState()
	const [selectedUserId, setSelectedUserId] = useState(0)

	const actionButtons = (record:any) => {
		if (isAdmin) {
			return (
				<Space size="middle">
					<Button size={'middle'} onClick={() => edit(record)}>Izmeni</Button>
					<Button danger size={'middle'} onClick={() => {
						setSelectedRecord(record)
						setModalBrisanjeKorisnikaVisible(true)
					}}>Obriši</Button>
				</Space>
			)
		}
	}

	const columns = [
		{
			title: 'Ime i prezime',
			dataIndex: 'name',
			key: 'name',
			render: (text:any) => text,
		},
		{
			title: 'Telefon',
			dataIndex: 'phone',
			key: 'phone',
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'Uloga',
			key: 'roles',
			dataIndex: 'roles',
			render: (tags:any) => (
				<>
					{tags.map((tag:any, index:any) => {
						let color = tag === 'Administrator' ? 'volcano' : 'blue';

						return (
							<Tag color={color} key={index}>
								{tag.toUpperCase()}
							</Tag>
						);
					})}
				</>
			),
		},
		{
			title: '',
			key: 'action',
			render: (record:any) => actionButtons(record),
		},
	];

	const handleTableChange = (pagination: any) => {
		setPage(pagination.current)
	}

	const del = (record: any) => {
		Modal.confirm({
			content: 'Da li ste sigurni?',
			cancelText: 'Otkaži',
			onOk: () => {
				axios.post(
						`/users/${record.id}/disable`,
						{targetUserId: selectedUserId}
					)
					.then(() => {
						setModalBrisanjeKorisnikaVisible(false)
						setReload(!reload)
						Modal.success({
							content: 'Uspešno ste obrisali korisnika.',
						})
					})
					.catch((error) => {
						Modal.error({
							content: 'Greška: '+error,
						});
					})
			},
			onCancel: () => {return}
		});
	}

	const edit = (user:any) => {
		history.push('/korisnici/edit/'+user.id)
	}

	const createNewUserButton = () => {
		if (isAdmin) {
			return (
				<Link to={'/korisnici/novi'}>
					<Button type="primary" size={'large'} className={'button_novi'}>Novi korisnik</Button>
				</Link>
			)
		}
	}

	const onModalClose = () => {
		setModalBrisanjeKorisnikaVisible(false)
		setReload(!reload)
		setSelectedUserId(0)
	}

	return (
		<DefaultLayout>
			<Content style={{ padding: '0 50px' }}>
				<Breadcrumb style={{ margin: '16px 0' }}>
					<Breadcrumb.Item>Početna</Breadcrumb.Item>
					<Breadcrumb.Item>Korisnici</Breadcrumb.Item>
				</Breadcrumb>
				{createNewUserButton()}
				<Table columns={columns}
				       dataSource={users.map((user, index) => (
								{
									key:index,
									id:user.id,
									name:user.first_name + ' ' + user.last_name,
									phone:user.phone,
									email:user.email,
									roles:user.roles.map(role => role.description)
								}))}
				       pagination={pagination}
				       onChange={handleTableChange}
				/>
			</Content>

			<Modal
				title="Prebacivanje predmeta drugom korisniku"
				open={modalBrisanjeKorisnikaVisible}
				onCancel={onModalClose}
				// onOk={() => del(selectedRecord)}
				destroyOnClose={true}
				okText={'Potvrdi'}
				cancelText={'Otkaži'}
				footer={[
					<Button key={0} disabled={selectedUserId === 0} onClick={() => del(selectedRecord)}>
						OK
					</Button>,
					<Button key={1} onClick={onModalClose}>
						Otkaži
					</Button>,
				]}
			>
				<Select
					placeholder="Izaberite korisnika kojem prebacujete predmete"
					onChange={(value) => {
						setSelectedUserId(value)
					}}
				>
					{
						users.map((user, index) => {
							return (
								<Option key={index} value={user.id}>{user.first_name + ' ' + user.last_name}</Option>
							)
						})
					}
				</Select>
			</Modal>
		</DefaultLayout>
	)
}

export default Users;