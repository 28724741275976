import React, {useState} from "react";
import {Breadcrumb, Button, Modal, Space, Table, Select} from "antd";
import {Content} from "antd/lib/layout/layout";
import DefaultLayout from "../../Components/DefaultLayout";
import {Link, useHistory} from "react-router-dom";
import useInvestors from "../../Hooks/Investors/useInvestors";
import axios from "axios";
import useIsAdmin from "../../Hooks/Users/useIsAdmin";

import useSearchInvestors from "../../Hooks/Investors/useSearchInvestors";
const {Option} = Select;

const Investors:React.FC = () => {

	const {isAdmin} = useIsAdmin()

	// const {searchedCases} = useSearchCases(autocompleteSearchValue)

	const actionButtons = (record:any) => {
		if (isAdmin) {
			return (
				<Space size="middle">
					<Button size={'middle'} onClick={() => edit(record)}>Izmeni</Button>
					<Button danger size={'middle'} onClick={() => del(record)}>Obriši</Button>
				</Space>
			)
		}
	}

	const columns = [
		{
			title: 'Naziv investitora',
			dataIndex: 'name',
			key: 'name',
			render: (text:any) => text,
		},
		{
			title: 'Kontakt telefon',
			dataIndex: 'phone',
			key: 'phone',
		},
		{
			title: 'Telefon firme',
			dataIndex: 'company_phone',
			key: 'company_phone',
		},
		{
			title: 'Adresa',
			dataIndex: 'address',
			key: 'address',
		},
		{
			title: 'Poštanski broj',
			dataIndex: 'postal_code',
			key: 'postal_code',
		},
		{
			title: 'Mesto',
			dataIndex: 'city',
			key: 'city',
		},
		{
			title: '',
			key: 'action',
			render: (record:any) => actionButtons(record),
		},
	];

	const del = (record:any) => {
		Modal.confirm({
			content: 'Da li ste sigurni da želite da obrišete investitora?',
			cancelText: 'Otkaži',
			onOk: () => {
				axios.delete(`/investors/${record.id}`)
					.then(() => {
						setReload(!reload)
						Modal.success({
							content: 'Uspešno ste obrisali investitora.',
						})
					})
					.catch((error) => {
						Modal.error({
							content: 'Greška: '+error,
						});
					})
			},
			onCancel: () => {return}
		});
	}

	const edit = (investor:any) => {
		history.push('/investitori/edit/'+investor.id)
	}

	const history = useHistory();
	const [page, setPage] = useState(1)
	const [reload, setReload] = useState(false)
	const {investors, pagination} = useInvestors(page, reload)
	const [autocompleteSearchValue, setAutocompleteSearchValue] = useState<string>('')
	// renamed investors to searchedInvestors because we already have investors from useInvestors() hook
	const {investors: searchedInvestors} = useSearchInvestors(autocompleteSearchValue)

	const handleTableChange = (pagination: any) => {
		setPage(pagination.current)
	}

	const handleChangeAutocomplete = (value:any) => {
		history.push('/investitori/edit/'+value)
	}

	return (
		<DefaultLayout>
			<Content style={{ padding: '0 50px' }}>
				<Breadcrumb style={{ margin: '16px 0' }}>
					<Breadcrumb.Item>Početna</Breadcrumb.Item>
					<Breadcrumb.Item>Investitori</Breadcrumb.Item>
				</Breadcrumb>
				<div className='search_investors'>
					<Link to={'/investitori/novi'}>
						<Button type="primary" size={'large'} className={'button_novi'}>Novi investitor</Button>
					</Link>
					<Select
						placeholder='Unesite termin za pretragu...'
						className={'search'}
						showSearch
						onSearch={(value:string) => {setAutocompleteSearchValue(value)}}
						filterOption={false}
						onChange={handleChangeAutocomplete}
						value={autocompleteSearchValue}
					>
						{searchedInvestors?.map((item, index) =>{
							return (
								<Option
									key={index}
									value={item.id}
								>
									{item.first_name+' '+item.last_name+' '+item.company_name}
								</Option>
							)
						})}
					</Select>
				</div>
				<Table columns={columns}
				       dataSource={investors.map((investor, index) => {
				       	 let investorFullName = investor.first_name ? investor.first_name : '';
				       	 investorFullName = investor.last_name ? investorFullName + ' ' + investor.last_name : investorFullName;
				       	 if (investor.company_name) {
					         if (investorFullName.trim().length > 0) {
						         investorFullName += ' - ' + investor.company_name
					         } else {
						         investorFullName = investor.company_name
					         }
				         }
					       return {
						       key: index,
						       id: investor.id,
						       name: investorFullName,
						       phone: investor.contact_person_phone,
						       address: investor.address,
						       postal_code: investor.postal_code,
						       city: investor.city,
					       }
				       })}
				       pagination={pagination}
				       onChange={handleTableChange}
				/>
			</Content>
		</DefaultLayout>
	)
}

export default Investors;