import React, {useEffect, useState} from "react";
import {Content} from "antd/lib/layout/layout";
import {Breadcrumb, Button, Col, Form, Input, Modal, Row} from "antd";
import DefaultLayout from "../../Components/DefaultLayout";
import Title from "antd/es/typography/Title";
import styled from "styled-components";
import axios from "axios";
import { Redirect } from "react-router-dom";
import useGetInvestor from "../../Hooks/Investors/useGetInvestor";
import TextArea from "antd/es/input/TextArea";

const formItemLayout = {
	labelCol: {
		xs: {
			span: 24,
		},
		sm: {
			span: 8,
		},
	},
	wrapperCol: {
		xs: {
			span: 24,
		},
		sm: {
			span: 16,
		},
	},
};

const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 16,
			offset: 8,
		},
	},
};

const FormTitle = styled.div`
	text-align: center;
	margin-bottom: 30px;
`

const InvestorEdit: React.FC = (props:any) => {

	const [form] = Form.useForm();
	const [redirect, setRedirect] = useState(false);
	const {investor} = useGetInvestor(props.match.params.id)

	useEffect(() => {
		form.setFieldsValue({
			first_name: investor?.first_name,
			last_name: investor?.last_name,
			phone: investor?.phone,
			company_name: investor?.company_name,
			company_phone: investor?.company_phone,
			address: investor?.address,
			postal_code: investor?.postal_code,
			city: investor?.city,
			note: investor?.note,
			contact_person_first_name: investor?.contact_person_first_name,
			contact_person_last_name: investor?.contact_person_last_name,
			contact_person_phone: investor?.contact_person_phone,
		})

	}, [investor, form])

	// Removed for now, employers also have need to edit investors
	// if (!isAdmin) {
	// 	return(<Redirect to={'/forbidden'} />)
	// }

	const onFinish = async (formValues:any) => {
		try {
			await axios.patch(`/investors/${props.match.params.id}`, formValues)
			Modal.success({
				content: 'Investitor je uspešno ažuriran',
				onOk() {
					setRedirect(true)
				},
			});
		} catch(error) {
			console.log(error)
			let message = ''
			if (error.response) {
				message = error.response.data.message
			} else {
				message = error
			}
			Modal.error({
				content: 'Greška prilikom snimanja investitora u bazu: ' + message,
			});
		}
	};

	if (redirect) {
		return(<Redirect to={'/investitori'} />)
	}

	return (
		<DefaultLayout>
			<Content style={{ padding: '0 50px' }}>
				<Breadcrumb style={{ margin: '16px 0' }}>
					<Breadcrumb.Item>Početna</Breadcrumb.Item>
					<Breadcrumb.Item>Investitori</Breadcrumb.Item>
					<Breadcrumb.Item>Ažuriranje podataka</Breadcrumb.Item>
				</Breadcrumb>
				<Row>
					<Col lg={{span: 12, offset: 6}} md={{span: 16, offset: 4}} xs={{span: 24, offset: 0}} >

						<FormTitle>
							<Title level={3}>Ažuriranje investitora</Title>
						</FormTitle>
						<Form
							{...formItemLayout}
							form={form}
							name="create"
							onFinish={onFinish}
							scrollToFirstError
						>
							<Form.Item
								name="first_name"
								label="Ime"
								rules={[
									{
										required: false,
										message: 'Unesite ime',
									},
								]}
							>
								<Input placeholder="npr. Petar" />
							</Form.Item>

							<Form.Item
								name="last_name"
								label="Prezime"
								rules={[
									{
										required: false,
										message: 'Unesite prezime',
									},
								]}
							>
								<Input placeholder="npr. Petrović" />
							</Form.Item>

							<Form.Item
								name="phone"
								label="Telefon"
								rules={[
									{
										required: false,
										message: 'Unesite broj telefona',
									},
								]}
							>
								<Input
									style={{
										width: '100%',
									}}
									placeholder="npr. 0641234567"
								/>
							</Form.Item>

							<Form.Item
								name="company_name"
								label="Naziv firme"
								rules={[
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value && (getFieldValue('first_name') === undefined && getFieldValue('last_name') === undefined)) {
												return Promise.reject(new Error('Unesite naziv firme ukoliko niste uneli ime i prezime'));
											}
											return Promise.resolve();
										},
									}),
								]}
							>
								<Input placeholder="npr. Kompanija doo" />
							</Form.Item>

							<Form.Item
								name="company_phone"
								label="Telefon firme"
								rules={[
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value && getFieldValue('phone') === undefined ) {
												return Promise.reject(new Error('Unesite telefon firme ukoliko niste uneli kontakt telefon'));
											}
											return Promise.resolve();
										},
									}),
								]}
							>
								<Input
									style={{
										width: '100%',
									}}
									placeholder="npr. 0641234567"
								/>
							</Form.Item>

							<Form.Item
								name="address"
								label="Adresa"
								rules={[
									{
										required: true,
										message: 'Unesite adresu',
									},
								]}
							>
								<Input placeholder="npr. Ulica 1" />
							</Form.Item>

							<Form.Item
								name="postal_code"
								label="Poštanski broj"
								rules={[
									{
										required: true,
										message: 'Unesite poštanski broj',
									},
								]}
							>
								<Input placeholder="npr. 15000" />
							</Form.Item>

							<Form.Item
								name="city"
								label="Mesto"
								rules={[
									{
										required: true,
										message: 'Unesite mesto',
									},
								]}
							>
								<Input placeholder="npr. Šabac" />
							</Form.Item>

							<Form.Item
								name="note"
								label="Napomena"
							>
								<TextArea
									placeholder="Dodatne napomene vezane za investitora..."
									rows={5}
								/>
							</Form.Item>


							<Form.Item
								name="contact_person_first_name"
								label="Ime kontakt osobe"
								rules={[
									{
										required: false,
										message: 'Unesite ime',
									},
								]}
							>
								<Input placeholder="npr. Petar" />
							</Form.Item>

							<Form.Item
								name="contact_person_last_name"
								label="Prezime kontakt osobe"
								rules={[
									{
										required: false,
										message: 'Unesite prezime',
									},
								]}
							>
								<Input placeholder="npr. Petrović" />
							</Form.Item>

							<Form.Item
								name="contact_person_phone"
								label="Telefon kontakt osobe"
								rules={[
									{
										required: false,
										message: 'Unesite broj telefona. Samo brojevi su dozvoljeni',
										pattern: new RegExp(/\d+/g),
									},
								]}
							>
								<Input
									style={{
										width: '100%',
									}}
									placeholder="npr. 0641234567"
								/>
							</Form.Item>

							<Form.Item {...tailFormItemLayout}>
								<Button size={"large"} type="primary" htmlType="submit">
									Snimi
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Content>
		</DefaultLayout>
	)
}

export default InvestorEdit