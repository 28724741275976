import React, {useState} from "react";
import useGetUserPoints from "../../Hooks/UserPoints/useGetUserPoints";
import useGetUserId from "../../Hooks/Users/useGetUserId";
import ICase from "../../Interfaces/ICase";
import ITablaColumn from "../../Interfaces/ITablaColumn";
import {AlignType} from "rc-table/lib/interface";
import {Table} from "antd";

const PointsTab: React.FC = () => {

	const {userId} = useGetUserId()
	const [page, setPage] = useState(1)
	const {points, pagination} = useGetUserPoints(page, 10, false, userId)
	const columns = [
		{
			title: 'Opis',
			dataIndex: 'description',
			key: 'description',
		},
		{
			title: 'Predmet',
			dataIndex: 'predmet',
			key: 'predmet',
			render: (predmet: ICase) => {
				return (
					<>
						<label>{predmet.naziv}</label><br />
						<small>{predmet.opis}</small>
					</>
				)
			},
		},
		{
			title: 'Iz kolone',
			dataIndex: 'start_column',
			key: 'start_column',
			render: (column: ITablaColumn) => column.title,
		},
		{
			title: 'U kolonu',
			dataIndex: 'end_column',
			key: 'start_column',
			render: (column: ITablaColumn) => column.title,
		},
		{
			title: 'Bodova',
			dataIndex: 'points',
			key: 'points',
			align: 'center' as AlignType,
		},
		{
			title: 'Odobreno',
			dataIndex: 'approved',
			key: 'approved',
			hidden: true
		},
	];

	const dataSource = () => {
		return points?.map((point, index) => (
			{
				key:index,
				id:point.id,
				description: point.description,
				predmet: point.case,
				start_column: point.start_column,
				end_column: point.end_column,
				mentor: point.mentor,
				points: point.points,
				approved: point.approved,
			}))
	}

	const handleTableChange = (pagination: any) => {
		setPage(pagination.current)
	}

	return (
		<>
			<Table
				dataSource={dataSource()}
				columns={columns}
				pagination={pagination}
				onChange={handleTableChange}
			/>
		</>
	)
}

export default PointsTab