import {useEffect, useState} from "react";
import axios from "axios";
import {Modal} from "antd";

type IReportRow = {
	name: string
	total: number
	key: number
}

const useGetGenericIzvestaj = (reportName: string) => {

	const [report, setReport] = useState<IReportRow[]>([])

	useEffect(() => {
		axios.get(`/izvestaj?reportName=${reportName}`)
			.then((response) => {
				let keyedArray = response.data.map(function(row: any, index: number) {
					row.key = index
					return row
				})
				setReport(keyedArray)
			})
			.catch((error) => {
				console.log(error)
				Modal.error({
					content: 'Greška: '+error,
				});
			})
	}, [reportName])

	return {
		report
	}
}

export default useGetGenericIzvestaj