import React from "react";
import {Button, Modal} from "antd";
import ITablaColumn from "../Interfaces/ITablaColumn";
import ColumnEditForm from "../Forms/ColumnEditForm";
import axios from "axios";

type IProps = {
	column: ITablaColumn,
	visible: boolean,
	setVisible: any,
	reloadParent: boolean,
	setReloadParent: any,
}

const ModalColumnSettings: React.FC<IProps> = ({column, visible, setVisible, reloadParent, setReloadParent}) => {

	const onModalClose = () => {
		setVisible(false)
		setReloadParent(!reloadParent)
	}

	const onFinishForm = async (formValues:any) => {
		try {
			await axios.patch(`/columns/${column.id}`, formValues)
			Modal.success({
				content: 'Kolona je uspešno snimljena',
				onOk() {
					onModalClose()
				},
			});
		} catch(error) {
			console.log(error)
			Modal.error({
				content: 'Greška prilikom snimanja kolone u bazu: '+error,
			});
		}
	};

	return(
		<Modal
			title="Podešavanje kolone"
			open={visible}
			onCancel={onModalClose}
			destroyOnClose={true}
			okText={'Potvrdi'}
			cancelText={'Otkaži'}
			footer={[
				<Button key={0} onClick={onModalClose}>
					Otkaži
				</Button>,
			]}
		>
			<ColumnEditForm
				column={column}
				onFinishHandler={onFinishForm}
			/>
		</Modal>
	)
}

export default ModalColumnSettings