const initialData = {
	id: 1,
	title: "Naslov table",
	description: "Opis table",
	tasks: {
		'initial_predmet_1': {
			id: 'initial_predmet_1',
			case_title: 'Legalizacija kuće',
			investor: 'Mitar Mitrović',
			task_owner: 1,
			status_id: 1,
			priority: 'normal',
			mentor_id: null,
			verified: false
		},
	},
	columns: {
		'column-1': {
			id: 'column-1',
			title: 'Nema ništa',
			taskIds: [],
			order: 1,
			points: 0,
		},
		'column-2': {
			id: 'column-2',
			title: 'Rešenje o rušenju',
			taskIds: [],
			order: 2,
			points: 0,
		},
		'column-3': {
			id: 'column-3',
			title: 'Obaveštenje 1',
			taskIds: [],
			order: 3,
			points: 0,
		},
		'column-4': {
			id: 'column-4',
			title: 'Urgencija',
			taskIds: [],
			order: 4,
			points: 0,
		},
		'column-5': {
			id: 'column-5',
			title: 'Obav. 2 neizmereno',
			taskIds: [],
			order: 5,
			points: 0,
		},
		'column-6': {
			id: 'column-6',
			title: 'Obav. 2 izmereno',
			taskIds: [],
			order: 6,
			points: 0,
		},
		'column-7': {
			id: 'column-7',
			title: 'Saglasnost dr. organa',
			taskIds: [],
			order: 7,
			points: 0,
		},
		'column-8': {
			id: 'column-8',
			title: 'Predat projekat',
			taskIds: [],
			order: 8,
			points: 0,
		},
		'column-9': {
			id: 'column-9',
			title: 'Obaveštenje 3',
			taskIds: [],
			order: 9,
			points: 0,
		},
		'column-10': {
			id: 'column-10',
			title: 'Dobijeno rešenje',
			taskIds: [],
			order: 10,
			points: 0,
		},
	},
	columnOrder: [
		'column-1',
		'column-2',
		'column-3',
		'column-4',
		'column-5',
		'column-6',
		'column-7',
		'column-8',
		'column-9',
		'column-10',
	]
}

export default initialData;