import {useEffect, useState} from "react";
import axios from "axios";
import {Modal} from "antd";
import ITabla from "../../Interfaces/ITabla";

const useGetDashboardsForDropDown = (reload: boolean) => {
	const [dashboards, setDashboards] = useState<ITabla[]>()

	useEffect(() => {
		axios.get(`/table/drop-down`)
			.then((response) => {
				setDashboards(response.data.data)
			})
			.catch((error) => {
				console.log(error)
				Modal.error({
					content: 'Greška: '+error,
				});
			})
	}, [reload])

	return {
		dashboards
	}
}

export default useGetDashboardsForDropDown