import React from "react";
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label} from 'recharts';
import useGetUserId from "../../Hooks/Users/useGetUserId";
import useGetReportGodisnjiPoMesecima from "../../Hooks/Reports/useGetReportGodisnjiPoMesecima";

const IzvestajTab: React.FC = () => {

	const {userId} = useGetUserId()
	const {report} = useGetReportGodisnjiPoMesecima(userId)

	return (
		<ResponsiveContainer width="100%" height={450}>
			<LineChart
				width={500}
				height={300}
				data={report}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="month" />
				<YAxis type="number" domain={[0, Math.max(...report.map(o => o.total))+10]}>
					<Label dx={-20} style={{
							textAnchor: "middle",
							fontSize: "100%",
							fill: "grey",
						}}
			       angle={270}
			       value="Bodova"/>
				</YAxis>
				<Tooltip />
				<Legend />
				<Line type="linear" dataKey="total" stroke="#8884d8" name="Broj bodova" />
			</LineChart>
		</ResponsiveContainer>
	);
}

export default IzvestajTab