import React, {useEffect, useState} from "react";
import {Layout, Menu} from "antd";
import {Link} from "react-router-dom";
import {BellOutlined, HomeOutlined, TabletOutlined, FormOutlined, EuroOutlined, UserOutlined, AimOutlined, PieChartOutlined } from "@ant-design/icons";
import useGetUserId from "../Hooks/Users/useGetUserId";
import {logout} from "../helpers";
import useIsAdmin from "../Hooks/Users/useIsAdmin";
import useIsAuthed from "../Hooks/Users/useIsAuthed";
import {selectedMenuArr} from "../helpers"
import {useDispatch, useSelector} from "react-redux";
import {setNotificationsNo} from "../Redux/notificationsSlice";
import useGetNotificationsCount from "../Hooks/Users/useGetNotificationsCount";

const DefaultLayout: React.FC = (props) => {

	const { Header, Footer} = Layout;
	const {isAdmin, setIsAdmin} = useIsAdmin()
	const {setAuthedHelper} = useIsAuthed()
	const userName = localStorage.getItem('user_name')
	const {userId} = useGetUserId();
	const {notifications} = useGetNotificationsCount(false, userId)
	// @ts-ignore
	const notificationsLabel = useSelector(state => state.notifications.value)
	const dispatch = useDispatch()
	const [selectedMenu, setSelectedMenu] = useState(selectedMenuArr())

	const menuItems = [
		{
			key: 'home',
			icon: <HomeOutlined />,
			label: <Link to={'/'}>Izabrana tabla</Link>,
		},
		{
			key: 'table',
			icon: <TabletOutlined />,
			label: <Link to={'/table'}>Table</Link>,
		},
		{
			key: 'predmeti',
			icon: <FormOutlined />,
			label: <Link to={'/predmeti'}>Predmeti</Link>,
		},
		{
			key: 'investitori',
			icon: <EuroOutlined />,
			label: <Link to={'/investitori'}>Investitori</Link>,
		},
		{
			key: 'korisnici',
			icon: <UserOutlined />,
			label: <Link to={'/korisnici'}>Korisnici</Link>
		},
		{
			key: 'user-menu',
			className: 'menu-item-right',
			// icon: <BellOutlined style={notifications.length > 0 ? {color: "red"} : {}}/>,
			label:  <span>{userName}</span>,
			children: [
				{
					key: 'profilna',
					label: <Link to={'/profilna'}>Profilna stranica</Link>
				},
				{
					key: 'logout',
					label: <span>Odjavi se</span>,
					onClick: () => logout(setIsAdmin, setAuthedHelper),
				},
			],
		},
		{
			key: 'notifikacije',
			className: 'menu-item-right',
			icon: <BellOutlined style={notifications > 0 ? {color: "red"} : {}}/>,
			label:  <Link to={'/notifikacije'} style={notifications > 0 ? {color: "red"} : {}}>
								{notificationsLabel}
							</Link>,
		},
	];

	if (isAdmin) {
		menuItems.push(
			{
				key: 'bodovi',
				icon: <AimOutlined />,
				label: <Link to={'/bodovi'}>Bodovi</Link> //todo renderovati samo ako je admin
			}
		)
		menuItems.push(
			{
				key: 'izvestaji',
				icon: <PieChartOutlined />,
				label: <Link to={'/izvestaji'}>Izveštaji</Link>
			}
		)
	}

	useEffect(() => {
		if (localStorage.getItem('authed') !== 'true') {
			logout(setIsAdmin, setAuthedHelper)
		}
	});

	useEffect(() => {
		dispatch(setNotificationsNo(notifications))
	}, [notifications, dispatch]);

	const handleOnClickMenu = (event: any) => {
		setSelectedMenu([event.key])
	}

	return (
		<Layout className="layout">
			<Header>
				<Menu
					theme="dark"
					mode="horizontal"
					selectedKeys={selectedMenu}
					onClick={(e) => handleOnClickMenu(e)}
					style={{ display: 'block' }}
					items={menuItems}
				/>
			</Header>
			{props.children}
			<Footer style={{ textAlign: 'center' }}>GD Knez doo, VI puka 54, 15000 Šabac ©2021 Aplikacija za upravljanje projektima</Footer>
		</Layout>
	)
}

export default DefaultLayout