import React from "react";
import ICasePoint from "../Interfaces/ICasePoint";
import {Form, Input} from "antd";

type IProps = {
	casePoint: ICasePoint
}

const CasePointFormItem: React.FC<IProps> = ({casePoint}) => {
	return (
		<Form.Item
			name={['points', casePoint.column_id]} // form data as nested array here: points[column-1]
			label={casePoint.column?.title}
			rules={[
				{
					pattern: new RegExp(/^[0-9]+$/),
					message: 'Samo su brojevi dozvoljeni',
				},
			]}
			hasFeedback
		>
			<Input type="number" />
		</Form.Item>
	)
}

export default CasePointFormItem