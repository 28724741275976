import {useEffect, useState} from "react";
import ITimelinePoint from "../../Interfaces/ITimelinePoint";
import axios from "axios";
import {Modal} from "antd";

const useGetCommentsHistory = (commentId: number|undefined, reload: boolean) => {
	const [comments, setComments] = useState<ITimelinePoint[]>([])

	useEffect(() => {
		if(!commentId) return

		axios.get(`/comments/history?comment_id=${commentId}`)
			.then((response) => {
				setComments(response.data.data)
			})
			.catch((error) => {
				console.log(error)
				Modal.error({
					content: 'Greška: '+error,
				});
			})
	}, [commentId, reload])

	return [comments];
}

export default useGetCommentsHistory