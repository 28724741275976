import {useEffect, useState} from "react";
import axios from "axios";
import {Modal} from "antd";
import IInvestor from "../../Interfaces/IInvestor";

const useSearchInvestors = (searchTerm: string) => {
	const [investors, setInvestors] = useState<IInvestor[]>([])
	const [internalReload, setInternalReload] = useState<boolean>(false)

	const setReload = () => {
			setInternalReload(!internalReload)
	}

	searchTerm = searchTerm ?? ''

	useEffect(() => {
		axios.get(`/investors/search?search=${searchTerm}`)
			.then((response) => {
				setInvestors(response.data.data)
			})
			.catch((error) => {
				console.log(error)
				Modal.error({
					content: 'Greška: '+error,
				});
			})
	}, [searchTerm, internalReload])

	return {
		investors,
		setInvestors,
		setReload,
	}
}

export default useSearchInvestors