import React, {useState} from "react";
import {Breadcrumb, Button, Modal, Space, Table} from "antd";
import {Content} from "antd/lib/layout/layout";
import DefaultLayout from "../../Components/DefaultLayout";
import axios from "axios";
import {useHistory} from "react-router-dom";
import useCases from "../../Hooks/Cases/useCases";
import useIsAdmin from "../../Hooks/Users/useIsAdmin";
import useGetUserId from "../../Hooks/Users/useGetUserId";

const Cases:React.FC = () => {

	const {isAdmin} = useIsAdmin()
	const {userId} = useGetUserId()

	const actionButtons = (record:any) => {
		if (isAdmin) {
			return (
				<Space size="middle">
					<Button size={'middle'} onClick={() => edit(record)}>Izmeni</Button>
					<Button danger size={'middle'} onClick={() => del(record)}>Obriši</Button>
				</Space>
			)
		} else if (record.nosilac_predmeta.id === parseInt(userId) || record.mentor?.id === parseInt(userId)) {
			return (
				<Space size="middle">
					<Button size={'middle'} onClick={() => edit(record)}>Izmeni</Button>
				</Space>
			)
		}
	}

	const columns = [
		{
			title: 'Broj predmeta',
			dataIndex: 'br_predmeta',
			key: 'br_predmeta',
			render: (text:any) => text,
		},
		{
			title: 'Naziv predmeta',
			dataIndex: 'naziv',
			key: 'naziv',
			render: (text:any) => text,
		},
		{
			title: 'Opis',
			dataIndex: 'opis',
			key: 'opis',
		},
		{
			title: 'Adresa',
			dataIndex: 'adresa',
			key: 'adresa',
		},
		{
			title: 'Opština',
			dataIndex: 'opstina',
			key: 'opstina',
		},
		{
			title: 'Investitor',
			dataIndex: 'investor',
			key: 'investor',
		},
		{
			title: 'Datum primanja',
			dataIndex: 'datum_primanja',
			key: 'datum_primanja',
		},
		{
			title: '',
			key: 'action',
			render: (record:any) => actionButtons(record),
		},
	];

	const del = (record:any) => {
		Modal.confirm({
			content: 'Da li ste sigurni da želite da obrišete predmet?',
			cancelText: 'Otkaži',
			onOk: () => {
				axios.delete(`/cases/${record.id}`)
					.then(() => {
						setReload(!reload)
						Modal.success({
							content: 'Uspešno ste obrisali predmet.',
						})
					})
					.catch((error) => {
						Modal.error({
							content: 'Greška: '+error,
						});
					})
			},
			onCancel: () => {return}
		});
	}

	const edit = (investor:any) => {
		history.push('/predmeti/edit/'+investor.id)
	}

	const history = useHistory();
	const [page, setPage] = useState(1)
	const [reload, setReload] = useState(false)
	const {cases, pagination} = useCases(page, reload)

	const handleTableChange = (pagination: any) => {
		setPage(pagination.current)
	}

	return (
		<DefaultLayout>
			<Content style={{ padding: '0 50px' }}>
				<Breadcrumb style={{ margin: '16px 0' }}>
					<Breadcrumb.Item>Početna</Breadcrumb.Item>
					<Breadcrumb.Item>Predmeti</Breadcrumb.Item>
				</Breadcrumb>
				<Table columns={columns}
				       dataSource={cases.map((predmet, index) => {
					       return {
						       key: index,
						       id: predmet.id,
						       br_predmeta: predmet.br_predmeta,
						       naziv: predmet.naziv,
						       opis: predmet.opis,
						       adresa: predmet.adresa,
						       opstina: predmet.opstina,
						       investor: predmet.investor.full_name,
						       datum_primanja: predmet.datum_primanja,
						       nosilac_predmeta: predmet.nosilac_predmeta,
						       mentor: predmet.mentor
					       }
				       })}
				       pagination={pagination}
				       onChange={handleTableChange}
				/>
			</Content>
		</DefaultLayout>
	)
}

export default Cases;