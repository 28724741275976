import {useEffect, useState} from "react";
import IInvestor from "../../Interfaces/IInvestor";
import axios from "axios";
import {Modal} from "antd";
import IAntdPagination from "../../Interfaces/IAntdPagination";

const useInvestors = (page: number, reload: boolean) => {
	const [investors, setInvestors] = useState<IInvestor[]>([])
	const [pagination, setPagination] = useState<IAntdPagination>()

	useEffect(() => {
		axios.get(`/investors?page=${page}`)
			.then((response) => {
				setInvestors(response.data.data)
				const pagination: IAntdPagination = {
					total: response.data.meta.total,
					pageSize: response.data.meta.per_page,
					current: response.data.meta.current_page,
				}
				setPagination(pagination)
			})
			.catch((error) => {
				console.log(error)
				Modal.error({
					content: 'Greška: '+error,
				});
			})
	}, [page, reload])

	return {
		investors,
		pagination
	}
}

export default useInvestors