import React from "react";
import {Bar, BarChart, CartesianGrid, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import useGetReportGodisnjiPoMesecima from "../../Hooks/Reports/useGetReportGodisnjiPoMesecima";

const BodoviPoMesecima: React.FC = () => {

	const {report} = useGetReportGodisnjiPoMesecima()
	const colors = ['#264653', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51', '#606c38', '#283618', '#fefae0', '#dda15e', '#bc6c25',
		'#8ecae6', '#219ebc', '#023047', '#ffb703', '#fb8500', '#ccd5ae', '#e9edc9', '#faedcd', '#cdb4db', '#ffc8dd', '#ffafcc', '#ff006e',
		'#8338ec', '#e63946']

	const reportData = () => {
		const newArray:any = [];
		report.forEach(function(item) {
			if (item.month && item.name) {
				if (!newArray[item.month]) {
					newArray[item.month] = {
						name: ""
					}
				}
				newArray[item.month].name = item.month
				newArray[item.month][item.name] = item.total
			}
		})
		const finalReport:any = []
		for (let key in newArray) {
			if (newArray.hasOwnProperty(key))
				finalReport.push(newArray[key])
		}
		return finalReport
	}

	const getNames = () => {
		let names: any = [];
		report.forEach(function(item) {
			if (item.month && item.name) {
				if (names.indexOf(item.name) === -1) {
					names.push(item.name)
				}
			}
		})
		return names;
	}

	const getBars = () => {
		let names = getNames()
		return names.map(function (item: any, index: any) {
			return (<Bar key={index} dataKey={item} stackId="a" fill={colors[index]} name={item}/>)
		})
	}

	return (
		<>
			<ResponsiveContainer width="100%" height={700}>
				<BarChart
					width={500}
					height={300}
					data={reportData()}
					margin={{
						top: 20,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis>
						<Label dx={-30} style={{
							textAnchor: "middle",
							fontSize: "100%",
							fill: "grey",
						}}
		        angle={270}
		        value="Bodova" />
					</YAxis>
					<Tooltip />
					<Legend />
					{getBars()}
				</BarChart>
			</ResponsiveContainer>
		</>
	)
}

export default BodoviPoMesecima