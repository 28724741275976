import {useState} from "react";

const useIsAdmin = () => {
	const [isAdmin] = useState<boolean>(localStorage.getItem('isAdmin') === 'true')

	const setIsAdmin = (value:string|boolean) => {
		if (value === 'true' || value === true) {
			localStorage.setItem('isAdmin', 'true')
		} else {
			localStorage.removeItem('isAdmin')
		}
	}

	return {
		isAdmin,
		setIsAdmin
	}
}

export default useIsAdmin