import React, {useState} from "react";
import {Content} from "antd/lib/layout/layout";
import {Breadcrumb, Button, Col, Form, Input, Modal, Row, Select} from "antd";
import DefaultLayout from "../../Components/DefaultLayout";
import Title from "antd/es/typography/Title";
import styled from "styled-components";
import axios from "axios";
import { Redirect } from "react-router-dom";
import useIsAdmin from "../../Hooks/Users/useIsAdmin";

const { Option } = Select;

const formItemLayout = {
	labelCol: {
		xs: {
			span: 24,
		},
		sm: {
			span: 8,
		},
	},
	wrapperCol: {
		xs: {
			span: 24,
		},
		sm: {
			span: 16,
		},
	},
};

const tailFormItemLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 16,
			offset: 8,
		},
	},
};

const FormTitle = styled.div`
	text-align: center;
	margin-bottom: 30px;
`

const UserCreate: React.FC = () => {

	const [form] = Form.useForm();
	const [redirect, setRedirect] = useState(false);
	const {isAdmin} = useIsAdmin()

	const onFinish = async (formValues:any) => {
		try {
			await axios.post('/users', formValues)
			Modal.success({
				content: 'Korisnik je uspešno kreiran',
				onOk() {
					setRedirect(true)
				},
			});
		} catch(error) {
			console.log(error)
			Modal.error({
				content: 'Greška prilikom snimanja korisnika u bazu: '+error,
			});
		}
	};

	if (redirect) {
		return(<Redirect to={'/korisnici'} />)
	}

	if (!isAdmin) {
		return(<Redirect to={'/forbidden'} />)
	}

	return (
		<DefaultLayout>
			<Content style={{ padding: '0 50px' }}>
				<Breadcrumb style={{ margin: '16px 0' }}>
					<Breadcrumb.Item>Početna</Breadcrumb.Item>
					<Breadcrumb.Item>Korisnici</Breadcrumb.Item>
					<Breadcrumb.Item>Unos novog korisnika</Breadcrumb.Item>
				</Breadcrumb>
				<Row>
					<Col lg={{span: 12, offset: 6}} md={{span: 16, offset: 4}} xs={{span: 24, offset: 0}} >

						<FormTitle>
							<Title level={3}>Unos novog korisnika</Title>
						</FormTitle>
						<Form
							{...formItemLayout}
							form={form}
							name="create"
							onFinish={onFinish}
							scrollToFirstError
						>
							<Form.Item
								name="first_name"
								label="Ime"
								rules={[
									{
										required: true,
										message: 'Unesite ime',
									},
								]}
							>
								<Input placeholder="npr. Petar" />
							</Form.Item>

							<Form.Item
								name="last_name"
								label="Prezime"
								rules={[
									{
										required: true,
										message: 'Unesite prezime',
									},
								]}
							>
								<Input placeholder="npr. Petrović" />
							</Form.Item>

							<Form.Item
								name="email"
								label="E-mail"
								rules={[
									{
										type: 'email',
										message: 'Unos nije validan email!',
									},
									{
										required: true,
										message: 'Unesite email',
									},
								]}
							>
								<Input placeholder="npr. petar.petrovic@gmail.com" />
							</Form.Item>

							<Form.Item
								name="phone"
								label="Mobilni"
								rules={[
									{
										required: true,
										message: 'Unesite broj mobilnog',
									},
								]}
							>
								<Input
									style={{
										width: '100%',
									}}
									placeholder="npr. 0641234567"
								/>
							</Form.Item>

							<Form.Item
								name="password"
								label="Password"
								rules={[
									{
										required: true,
										message: 'Uesite lozinku',
									},
									{
										min: 6,
										message: 'Lozinka mora imati min 6 karaktera',
									},
								]}
								hasFeedback
							>
								<Input.Password />
							</Form.Item>

							<Form.Item
								name="password_confirm"
								label="Confirm Password"
								dependencies={['password']}
								hasFeedback
								rules={[
									{
										required: true,
										message: 'Potvrdite lozinku',
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (!value || getFieldValue('password') === value) {
												return Promise.resolve();
											}

											return Promise.reject(new Error('Dve lozinke koje ste uneli se ne podudaraju!'));
										},
									}),
								]}
							>
								<Input.Password />
							</Form.Item>

							<Form.Item
								name="role"
								label="Uloga korisnika"
								rules={[
									{
										required: true,
										message: 'Unesite ulogu',
									},
								]}
							>
								<Select placeholder="izaberite ulogu korisnika">
									<Option value="admin">Administrator</Option>
									<Option value="user">Korisnik</Option>
								</Select>
							</Form.Item>

							<Form.Item {...tailFormItemLayout}>
								<Button size={"large"} type="primary" htmlType="submit">
									Kreiraj
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Content>
		</DefaultLayout>
	)
}

export default UserCreate