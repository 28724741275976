import React from "react";
import {Col, Divider, Row} from "antd";
import ICase from "../Interfaces/ICase";
import useIsAdmin from "../Hooks/Users/useIsAdmin";
import ICasePoint from "../Interfaces/ICasePoint";

type IProps = {
	predmet: ICase|undefined
}
const CaseDetails: React.FC<IProps> = (props:IProps) => {

	const {isAdmin} = useIsAdmin()

	const contactPersonFullName = () => {
		if( props.predmet?.investor.contact_person_first_name || props.predmet?.investor.contact_person_last_name) {
			let fullName = props.predmet?.investor.contact_person_first_name + ' ' + props.predmet?.investor.contact_person_last_name
			return fullName.trim()
		}
		return ''
	}

	// Ibačeno jer Miloš ne želi da prikaže ukupnu vrednost predmeta. Možda će hteti kasnije...
	// const vrednostPredmeta = () => {
	// 	let ukupnoBodova = 0;
	// 	props.predmet?.points?.forEach((value) => {
	// 		ukupnoBodova += value.points
	// 	})
	// 	return ukupnoBodova
	// }

	const vrednostTrenutneKolone = () => {
		let filteredColumn = props.predmet?.points?.filter((point: ICasePoint) => {
				return point.column_id === props.predmet?.currentColumn?.id
		})
		if (filteredColumn && filteredColumn.length > 0) {
			return filteredColumn[0].points
		}
		return 0
	}

	return (
		<>
			<Row gutter={16}>
				<Col md={8} className={'text-right'}>
					<strong>Broj predmeta:</strong>
				</Col>
				<Col md={16}>
					{props.predmet?.br_predmeta}
				</Col>
			</Row>
			<Row gutter={16}>
				<Col md={8} className={'text-right'}>
					<strong>Naziv predmeta:</strong>
				</Col>
				<Col md={16}>
					{props.predmet?.naziv}
				</Col>
			</Row>
			<Row gutter={16}>
				<Col md={8} className={'text-right'}>
					<strong>Opis:</strong>
				</Col>
				<Col md={16}>
					{props.predmet?.opis}
				</Col>
			</Row>
			<Row gutter={16}>
				<Col md={8} className={'text-right'}>
					<strong>Broj katastarske parcele:</strong>
				</Col>
				<Col md={16}>
					{props.predmet?.br_kat_parcele}
				</Col>
			</Row>
			<Row gutter={16}>
				<Col md={8} className={'text-right'}>
					<strong>Stari broj katastarske parcele:</strong>
				</Col>
				<Col md={16}>
					{props.predmet?.br_kat_parcele_stari}
				</Col>
			</Row>
			<Row gutter={16}>
				<Col md={8} className={'text-right'}>
					<strong>Katastarska opština:</strong>
				</Col>
				<Col md={16}>
					{props.predmet?.kat_opstina}
				</Col>
			</Row>
			<Row gutter={16}>
				<Col md={8} className={'text-right'}>
					<strong>Administrativna opština:</strong>
				</Col>
				<Col md={16}>
					{props.predmet?.opstina}
				</Col>
			</Row>
			<Row gutter={16}>
				<Col md={8} className={'text-right'}>
					<strong>Adresa:</strong>
				</Col>
				<Col md={16}>
					{props.predmet?.adresa}
				</Col>
			</Row>
			<Row gutter={16}>
				<Col md={8} className={'text-right'}>
					<strong>Napomena:</strong>
				</Col>
				<Col md={16}>
					{props.predmet?.napomena}
				</Col>
			</Row>
			{
				isAdmin ?
					<Row gutter={16}>
						<Col md={8} className={'text-right'}>
							<strong>Finansije:</strong>
						</Col>
						<Col md={16}>
							{props.predmet?.obrazlozenje}
						</Col>
					</Row>
					:
					''
			}

			<Row gutter={16}>
				<Col md={8} className={'text-right'}>
					<strong>Geometar:</strong>
				</Col>
				<Col md={16}>
					{props.predmet?.geometar}
				</Col>
			</Row>
			<Row gutter={16}>
				<Col md={8} className={'text-right'}>
					<strong>Nosilac predmeta:</strong>
				</Col>
				<Col md={16}>
					{props.predmet?.task_owner}
				</Col>
			</Row>
			<Row gutter={16}>
				<Col md={8} className={'text-right'}>
					<strong>Mentor predmeta:</strong>
				</Col>
				<Col md={16}>
					{props.predmet?.mentor
						? props.predmet?.mentor?.first_name + ' ' + props.predmet?.mentor?.last_name
						: ''
					}
				</Col>
			</Row>
			<Row gutter={16}>
				<Col md={8} className={'text-right'}>
					<strong>Vrednost kolone:</strong>
				</Col>
				<Col md={16}>
					{vrednostTrenutneKolone()}
				</Col>
			</Row>
			<Row gutter={16}>
				<Divider />
				<Col md={8} className={'text-right'}>
					<strong>Investitor:</strong>
				</Col>
				<Col md={16}>
					{props.predmet?.investor.full_name}<br/>
					{props.predmet?.investor.address}<br/>
					{props.predmet?.investor.city}
				</Col>
			</Row>
			<Row gutter={16}>
				<Col md={8} className={'text-right'}>
					<strong>Kontakt osoba:</strong>
				</Col>
				<Col md={16}>
					{ contactPersonFullName() }
				</Col>
			</Row>
			<Row gutter={16}>
				<Col md={8} className={'text-right'}>
					<strong>Telefon kontakt osobe:</strong>
				</Col>
				<Col md={16}>
					{props.predmet?.investor.contact_person_phone}
				</Col>
			</Row>
		</>
	)
}

export default CaseDetails